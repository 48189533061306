import createModuleReducer from 'store/storeConfig/createModuleReducer';

import {
  OmniChannelReducerProps,
  FetchChatsRequestActionProps,
  FetchChannelsSuccessActionProps,
  SetCurrentChannelActionProps,
  SetCurrentChatActionProps,
  SetUpdateChannelsOrderRequestActionProps,
  FetchInitialChannelFormSuccessActionProps,
  FetchSchoolUsersSuccessActionProps,
  FetchChatClassroomsSuccessActionProps,
  FetchChatStudentProfileSuccessActionProps,
  FetchPendingCountSuccessActionProps,
  FetchChatConfirmedResponsiblesSuccessActionProps,
  FetchChatsSuccessActionProps,
  FetchChatSearchUsersRequestActionProps,
  FetchChatSearchUsersSuccessActionProps,
  SetChatFilterParamsActionProps,
  SetDeleteChannelActionProps,
  FetchChannelSuccessActionProps,
  SetMessagesMarkReadStatusSuccessActionProps,
  FetchMessagesSuccessAction,
  FetchMessagesRequestActionProps,
  SetCreateNewMessageSuccessActionProps,
  SetDeleteMessageParamsActionProps,
  Chat,
  SetCurrentMessageActionProps,
  SetEditMessageSuccessActionProps,
  SetChatCurrentTabActionProps,
  SetChatSearchCurrentTabActionProps,
  Message,
  SetRedirectMessageSuccessActionProps,
  SetRedirectChannelPageActionProps,
  SetApproveMessageSuccessActionProps,
  FetchChannelsByStudentProfileSuccessActionProps,
  SetCurrentChatUserActionProps,
  SetCreateNewChatSuccessActionProps,
  FetchTicketsRequestActionProps,
  FetchTicketsSuccessActionProps,
  FetchTicketPendingCountSuccessActionProps,
  FecthCurrentChatSuccessActionProps,
  FetchUnreadMessagesSuccessActionProps,
  SetStartOrCloseAttendanceStatusProps,
  FetchMessageSuccessAction,
  SocketConnectSuccessActionProps,
  SetTransferTicketActionProps,
  FetchTicketStudentChannelsActionProps,
  Channel,
  ChannelOption,
  FetchTicketDetailSuccessActionProps,
  FetchApprovedMessageSuccessAction,
  FetchUnapprovedMessageSuccessAction,
  FetchDeletedMessageSuccessActionProps,
  FetchEditMessageSuccessActionProps,
  FetchChannelSubjectsSuccessActionProps,
  FetchChannelRequestActionProps,
  FetchNewChatClassroomsRequestActionProps,
  FetchNewChatClassroomsActionProps,
  HeadquarterRecipient,
  FetchNewChatStudentsRequestActionProps,
  FetchNewChatStudentsActionProps,
  FetchNewChatFamilyRequestActionProps,
  FetchNewChatFamilyActionProps,
  FetchNewChatResponsiblesRequestActionProps,
  FetchNewChatResponsiblesActionProps,
  SetChatFormFiltersActionProps,
} from './types';
import { ResultsActionDefault } from '../types';

import actions from './actions';
import { normalizeChatTicket } from 'core/helper/message/omni/normalizeChatTIcket';
import { normalizeNewMessageReducer } from 'core/helper/message/omni/normalizeNewMessageReducer';
import { normalizeFetchMessageReducer } from 'core/helper/message/omni/normalizeFetchMessageReducer ';
import { normalizeActiveChannel } from 'core/helper/message/omni/normalizeChannelParams';
import { normalizeDeletedMessage } from 'core/helper/message/omni/actions/normalizeDeletedMessage';
import { normalizeRedirectMessage } from 'core/helper/message/omni/actions/normalizeRedirectMessage';
import { normalizeFetchEditMessage } from 'core/helper/message/omni/actions/normalizeFetchEditMessage';

import _ from 'lodash';

export const INITIAL_STATE: OmniChannelReducerProps = {
  activeChannel: null,
  activeChat: null,
  activeChatUser: null,
  activeTicket: null,
  avatarIconsList: null,
  channel: null,
  channelSubjects: { isLoading: false, data: [] },
  channels: null,
  channelsStudentProfile: null,
  chatClassrooms: null,
  chatConfirmedResponsibles: null,
  chatCurrentTab: 0,
  chatFilters: {
    classroomId: null,
    educationalStageId: null,
    headquarterId: null,
    source: null,
    isInactiveChats: false,
    searchName: '',
    studentStatus: null,
    amount: 0,
    subjectId: null,
    chatKind: null,
  },
  chatIsSearchTab: false,
  chatPendingCount: null,
  chatSearchCurrentTab: 0,
  chatSearchPagination: {
    currentPage: 0,
    nextPage: 0,
  },
  chatSearchUsers: null,
  chatStudentProfiles: null,
  chats: null,
  classrooms: null,
  currentMessage: null,
  error: null,
  isLoading: false,
  isLoadingChannels: true,
  isLoadingEditMessage: false,
  isLoadingMessages: false,
  isLoadingSearchTab: false,
  isLoadingSendMessage: false,
  isRedirectMessage: false,
  isEditingMessage: false,
  isReplyMessage: false,
  isReportMessages: false,
  isSubmittingChannelForm: false,
  isSubmittingChatForm: false,
  isSubmittingTicketForm: false,
  isNewChat: false,
  messages: null,
  messagesPagination: {
    itemsCountPerPage: 0,
    page: 1,
    totalItemsCount: 0,
  },
  pagination: {
    itemsPerPage: 0,
    page: 1,
    totalItemsCount: 0,
    totalPages: 1,
  },
  schoolUsers: null,
  showAttachmentModal: false,
  showDeleteChannelModal: false,
  showFilterChatModal: false,
  showNewChannelModal: false,
  showRedirectMessageModal: false,
  showReportMessagesModal: false,
  showSideDetailsModal: false,
  showTransferTicketModal: false,
  showOpeningHoursModal: false,
  socket: null,
  ticketStudentChannels: [],
  unreadCount: {
    chats: [],
    total: 0,
  },
  chat: {
    form: {
      channel: null,
      isLoading: true,
      filters: {
        name: '',
      },
      showDiscardModal: false,
      classrooms: {
        data: null,
        isLoading: true,
        pagination: {
          page: 1,
          totalPages: 0,
        },
      },
      students: {
        data: null,
        isLoading: true,
        pagination: {
          page: 1,
          totalPages: 0,
        },
      },
      family: {
        data: null,
        isLoading: true,
        pagination: {
          page: 1,
          totalPages: 0,
        },
      },
      responsibles: {
        data: null,
        isLoading: true,
        pagination: {
          page: 1,
          totalPages: 0,
        },
      },
    },
  },
};

export const handlerError = (
  state: OmniChannelReducerProps,
  action: ResultsActionDefault
) => ({
  ...state,
  error: action.error,
  isLoading: false,
  isSubmittingChannelForm: false,
  isLoadingMessages: false,
  isLoadingSendMessage: false,
  isRedirectMessage: false,
});

export const fetchChannelsRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isLoading: true,
  isLoadingChannels: true,
});

export const fetchChannelsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChannelsSuccessActionProps
) => {
  if (action.channels.length < 1)
    return {
      ...state,
      isLoading: false,
      isLoadingChannels: false,
    };

  const findedChannelIndex = action.channels?.findIndex(
    (channel) => channel.id === action.channelId
  );

  const currentIndex = findedChannelIndex >= 0 ? findedChannelIndex : 0;

  const activeChannel = normalizeActiveChannel(action?.channels[currentIndex]);

  return {
    ...state,
    channels: action.channels,
    isLoading: false,
    activeChannel,
    isLoadingChannels: false,
  };
};

export const fetchChannelsByStudentProfileRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
});

export const fetchChannelsByStudentProfileSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChannelsByStudentProfileSuccessActionProps
) => ({
  ...state,
  channelsStudentProfile: action.channels,
});

export const setCurrentChannel = (
  state: OmniChannelReducerProps,
  action: SetCurrentChannelActionProps
) => ({
  ...state,
  activeChat: null,
  activeTicket: null,
  chatSearchCurrentTab: 0,
  pagination: {
    itemsPerPage: 0,
    page: 1,
    totalItemsCount: 0,
    totalPages: 1,
  },
  activeChannel: {
    ...action.params,
  },
  chatFilters: {
    classroomId: null,
    educationalStageId: null,
    headquarterId: null,
    source: null,
    isInactiveChats: false,
    searchName: '',
    studentStatus: null,
    amount: 0,
    chatKind: null,
  },
});

export const setCurrentChat = (
  state: OmniChannelReducerProps,
  action: SetCurrentChatActionProps
) => {
  const currentChat = state.chats?.find((chat) => chat.id === action.params.id);
  const currentTicket = currentChat?.relationships.ticket || null;

  return {
    ...state,
    activeChat: currentChat,
    activeTicket: currentTicket,
    currentMessage: null,
    isEditingMessage: false,
    isReplyMessage: false,
  };
};

export const setCurrentChatUser = (
  state: OmniChannelReducerProps,
  action: SetCurrentChatUserActionProps
) => {
  const chatUser = state.chatSearchUsers?.find(
    (chatUser) => chatUser?.id === action.chatUserId
  );

  const chat = chatUser?.attributes.chat_id
    ? {
        id: chatUser.attributes.chat_id,
        type: 'chat',
        attributes: {
          ...chatUser.attributes,
          classrooms_names: chatUser.attributes.classroom_names,
          main_user_role: chatUser.attributes.role,
          student_profile_id:
            chatUser.type === 'student_profile'
              ? chatUser.id
              : chatUser.attributes.student_id,
        },
        included: {
          ...chatUser,
        },
        relationships: {
          main_user: {
            data: chatUser,
          },
        },
      }
    : null;

  return {
    ...state,
    isNewChat: !chat,
    isLoadingChats: true,
    messages: null,
    activeChat: chat,
    activeChatUser: chatUser,
  };
};

export const setCurrentMessage = (
  state: OmniChannelReducerProps,
  action: SetCurrentMessageActionProps
) => ({
  ...state,
  currentMessage: action.message,
});

export const setChatFormFilters = (
  state: OmniChannelReducerProps,
  action: SetChatFormFiltersActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.filters = action.filters;
  return newState;
};

export const setMessagesMarkReadStatusRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
});

export const setMessagesMarkReadStatusSuccess = (
  state: OmniChannelReducerProps,
  action: SetMessagesMarkReadStatusSuccessActionProps
) => {
  if (state.chats?.length < 1)
    return {
      ...state,
    };

  const newList = state.chats?.map((chat) => {
    if (chat.id === action.chat.id) {
      return { ...action.chat, included: action.included[0] };
    }
    return chat;
  });

  return {
    ...state,
    activeChat: {
      ...action.chat,
      attributes: {
        ...action.chat.attributes,
        student_profile_name: state.activeChat?.attributes.student_profile_name,
        student_profile_id: state.activeChat?.attributes.student_profile_id,
      },
      included: action.included[0],
    },
    chats: newList,
  };
};

export const setUpdateChannelsOrderRequest = (
  state: OmniChannelReducerProps,
  action: SetUpdateChannelsOrderRequestActionProps
) => {
  const channels = [...state.channels];
  const newChannelOrder = channels?.sort((stateChannel, newChannel) => {
    const stateIndex = action.channelsIds.indexOf(stateChannel.id);
    const newIndex = action.channelsIds.indexOf(newChannel.id);

    return stateIndex - newIndex;
  });

  return {
    ...state,
    channels: newChannelOrder,
  };
};

export const setRedirectChannelPageSuccess = (
  state: OmniChannelReducerProps,
  action: SetRedirectChannelPageActionProps
) => {
  const newChannel = state.channels?.find(
    (channel) => channel.id == action.channelId
  );

  const newChat: Chat = {
    ...action.chat,
    included: action.included,
  };

  const activeChannel = normalizeActiveChannel(newChannel);

  return {
    ...state,
    chatIsSearchTab: false,
    messages: null,
    chatCurrentTab: 2,
    activeChannel,
    activeChat: newChat,
  };
};

export const fetchInitialChannelFormRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
  isLoading: true,
});

export const fetchInitialChannelFormSuccess = (
  state: OmniChannelReducerProps,
  action: FetchInitialChannelFormSuccessActionProps
) => ({
  ...state,
  avatarIconsList: action.icons,
  classrooms: action.classrooms,
  isLoading: false,
});

export const fetchSchoolUsersRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  schoolUsers: null,
});

export const fetchSchoolUsersSuccess = (
  state: OmniChannelReducerProps,
  action: FetchSchoolUsersSuccessActionProps
) => ({
  ...state,
  schoolUsers: action.schoolUsers,
});

export const setCreateNewChannelRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isSubmittingChannelForm: true,
});

export const setCreateNewChannelSuccess = (state: OmniChannelReducerProps) => ({
  ...state,
  showNewChannelModal: false,
  channels: null,
  isSubmittingChannelForm: false,
  avatarIconsList: null,
});

export const setCreateOldNewChatRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isSubmittingChatForm: true,
});

export const setCreateOldNewChatSuccess = (state: OmniChannelReducerProps) => ({
  ...state,
  isSubmittingChatForm: false,
  chats: null,
  chatCurrentTab: 2,
});

export const setCreateNewChatRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isSubmittingChatForm: true,
});

export const setCreateNewChatSuccess = (state: OmniChannelReducerProps) => ({
  ...state,
  isSubmittingChatForm: false,
  chats: null,
  chatCurrentTab: 2,
  chat: {
    ...state.chat,
    form: {
      ...state.chat.form,
      filters: {
        name: '',
      },
    },
  },
});

export const setCreateNewTicketBySchoolRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
  isSubmittingTicketForm: true,
});

export const setCreateNewTicketBySchoolSuccess = (
  state: OmniChannelReducerProps
) => ({
  ...state,
  isSubmittingTicketForm: false,
});

export const setCreateNewChatUserSuccess = (
  state: OmniChannelReducerProps,
  action: SetCreateNewChatSuccessActionProps
) => {
  const newChat: Chat = {
    ...action.chat,
    included: action.included,
  };

  const newChats = state.chatSearchUsers?.map((chatUser) => {
    if (chatUser.id === state.activeChatUser.id)
      return {
        ...chatUser,
        attributes: {
          ...chatUser.attributes,
          chat_id: action.chat.id,
        },
      };

    return chatUser;
  });

  return {
    ...state,
    activeChat: newChat,
    chatSearchUsers: newChats,
  };
};

export const setCreateNewMessageRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isLoadingSendMessage: true,
});

export const setCreateNewMessageSuccess = (
  state: OmniChannelReducerProps,
  action: SetCreateNewMessageSuccessActionProps
) => {
  const { messages, chats, activeChat } = normalizeNewMessageReducer(
    action,
    state.chats,
    state.activeChat,
    state.chatIsSearchTab,
    state.messages
  );

  return {
    ...state,
    messages,
    chats,
    activeChat,
    isLoadingSendMessage: false,
  };
};

export const fetchChannelRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isLoading: true,
  channel: null,
  isSubmittingChannelForm: false,
});

export const fetchChannelSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChannelSuccessActionProps
) => ({
  ...state,
  channel: action.channel,
  isLoading: false,
});

export const fetchChannelSubjectsRequest = (
  state: OmniChannelReducerProps
): OmniChannelReducerProps => ({
  ...state,
  channelSubjects: {
    ...state.channelSubjects,
    isLoading: true,
  },
});

export const fetchChannelSubjectsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChannelSubjectsSuccessActionProps
): OmniChannelReducerProps => ({
  ...state,
  channelSubjects: { data: action.channelSubjects, isLoading: false },
});

export const fetchChatClassroomsRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isLoading: true,
});

export const fetchChatClassroomsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChatClassroomsSuccessActionProps
) => ({
  ...state,
  chatStudentProfiles: null,
  chatClassrooms: action.chatClassrooms,
  isLoading: false,
});

export const fetchChatStudentProfilesRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
});

export const fetchchatStudentProfilesSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChatStudentProfileSuccessActionProps
) => ({
  ...state,
  chatStudentProfiles: action.studentProfiles,
});

export const fetchChatPendingCountRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
});

export const fetchChatPendingCountSuccess = (
  state: OmniChannelReducerProps,
  action: FetchPendingCountSuccessActionProps
) => {
  const isCurrentChannel = action.channelId === state.activeChannel.id;
  const newList = state.chatFilters.amount
    ? state.channels
    : state.channels?.map((channel) => {
        if (channel.id === action.channelId) {
          return {
            ...channel,
            attributes: {
              ...channel.attributes,
              pending_action_count:
                action.pendingCount.pending_answer_count +
                action.pendingCount.pending_approval_count,
            },
          };
        }
        return channel;
      });

  return {
    ...state,
    chatPendingCount: isCurrentChannel && action.pendingCount,
    channels: newList,
  };
};

export const fetchChatConfirmedResponsiblesRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
});

export const fetchChatConfirmedResponsiblesSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChatConfirmedResponsiblesSuccessActionProps
) => ({
  ...state,
  chatConfirmedResponsibles: action.confirmedResponsibles,
});

export const fetchChatsRequest = (
  state: OmniChannelReducerProps,
  action: FetchChatsRequestActionProps
) => ({
  ...state,
  chats: action.params.page === 1 ? null : state.chats,
});

export const fetchChatsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChatsSuccessActionProps
) => {
  const { included, chats } = action;

  const chatList = chats?.map((chat) => ({
    ...chat,
    included: included?.find(
      (includedObj) =>
        includedObj?.id === chat.relationships?.main_user?.data.id
    ),
  }));

  return {
    ...state,
    chats: action.page === 1 ? chatList : [...state.chats, ...chatList],
    pagination: {
      ...state.pagination,
      itemsPerPage: action.itemsCountPerPage,
      totalItemsCount: action.totalItemsCount,
      page: action.page,
    },
  };
};

export const fetchChannelFormRequest = (state: OmniChannelReducerProps) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.channel = null;
  newState.chat.form.isLoading = true;

  return newState;
};

export const fetchChannelFormSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChannelRequestActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.channel = action.channel;
  newState.chat.form.isLoading = false;

  return newState;
};

export const fetchCurrentChatSuccess = (
  state: OmniChannelReducerProps,
  action: FecthCurrentChatSuccessActionProps
) => {
  const isTicketChannel = state.activeChannel?.kind === 'ticket';

  const chatReadStatus = {
    unanswered: 0,
    answered: 2,
  };

  const ticketStatus = {
    waiting: 0,
    in_attendance: 1,
    done: 2,
  };

  return {
    ...state,
    activeChat: {
      ...action.chat,
      included: action.included,
    },
    chatCurrentTab: isTicketChannel
      ? ticketStatus[action.ticket.attributes.status]
      : chatReadStatus[action.chat.attributes.read_status],
    activeTicket: isTicketChannel ? action.ticket : state.activeTicket,
  };
};

export const fetchChatSearchUsersRequest = (
  state: OmniChannelReducerProps,
  action: FetchChatSearchUsersRequestActionProps
) => {
  return {
    ...state,
    isLoadingSearchTab: action.params.page === 1 ? true : false,
    chatSearchPagination: {
      ...state.chatSearchPagination,
      currentPage: action.params.page,
    },
  };
};

export const fetchChatSearchUsersSuccess = (
  state: OmniChannelReducerProps,
  action: FetchChatSearchUsersSuccessActionProps
) => {
  const list =
    state.chatSearchPagination.currentPage > 1
      ? [...state.chatSearchUsers, ...action.users]
      : action.users;

  return {
    ...state,
    chatSearchUsers: list,
    chatSearchPagination: {
      currentPage: action.currentPage,
      nextPage: action.nextPage,
    },
    isLoadingSearchTab: false,
  };
};

export const fetchDeletedMessageSuccess = (
  state: OmniChannelReducerProps,
  action: FetchDeletedMessageSuccessActionProps
) => {
  if (!action.message || !state.activeChat || !state.chats) return;

  const { chats, messages } = normalizeDeletedMessage({
    deletedMessage: action.message,
    messages: state.messages,
    chats: state.chats,
    activeChat: state.activeChat,
  });

  return {
    ...state,
    messages: messages,
    chats: chats,
  };
};

export const fetchEditMessageSuccess = (
  state: OmniChannelReducerProps,
  action: FetchEditMessageSuccessActionProps
) => {
  if (!action.message || !state.activeChat || !state.chats || !state.messages)
    return;

  const { messages, chats } = normalizeFetchEditMessage({
    activeChat: state.activeChat,
    chats: state.chats,
    messages: state.messages,
    editedMessage: action.message,
    includedMessage: action.included[0],
    approvalStatus: action.approvalStatus,
  });

  return {
    ...state,
    messages,
    chats,
  };
};

export const fetchMessagesRequest = (
  state: OmniChannelReducerProps,
  action: FetchMessagesRequestActionProps
) => {
  const isLoading = action.params.page === 1 && { isLoadingMessages: true };

  return {
    ...state,
    ...isLoading,
  };
};

export const fetchMessageSuccess = (
  state: OmniChannelReducerProps,
  action: FetchMessageSuccessAction
) => {
  let message: Message;
  let attachedMessage = null;

  if (action?.message.relationships.attachedMessage) {
    attachedMessage = action.included.find(
      (attached) => attached.type === 'message'
    );

    message = {
      ...action.message,
      relationships: {
        ...action.message.relationships,
        attachedMessage: {
          data: {
            ...attachedMessage,
          },
        },
      },
    };
  }

  const { chats, activeChat } = normalizeFetchMessageReducer(
    state.chats,
    message
  );

  return {
    ...state,
    messages: [message, ...state.messages],
    chats,
    activeChat,
  };
};

export const fetchNewChatClassroomsRequest = (
  state: OmniChannelReducerProps,
  action: FetchNewChatClassroomsRequestActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.classrooms.isLoading = action.page === 1;
  newState.chat.form.classrooms.pagination.page = action.page;

  return newState;
};

export const fetchNewChatClassroomsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchNewChatClassroomsActionProps
) => {
  let newList = [];

  let newHeadquarterList: HeadquarterRecipient[] = [...action.classrooms];

  if (state.chat.form.classrooms.pagination.page === 1) {
    newList = newHeadquarterList;
  } else {
    const list: HeadquarterRecipient[] = [];

    const newHeadquarters = action.classrooms?.map(
      (headquarter: HeadquarterRecipient) => headquarter.id
    );

    state.chat.form.classrooms.data.map((headquarter: HeadquarterRecipient) => {
      if (newHeadquarters.includes(headquarter.id)) {
        const newHeadquarterData: HeadquarterRecipient = action.classrooms.find(
          (item: HeadquarterRecipient) => item.id === headquarter.id
        );

        newHeadquarterList = newHeadquarterList.filter(
          (item) => item.id !== headquarter.id
        );

        return list.push({
          ...headquarter,
          classrooms: [
            ...headquarter.classrooms,
            ...newHeadquarterData.classrooms,
          ],
        });
      }

      list.push(headquarter);
    });

    newList = [...list, ...newHeadquarterList];
  }

  const newState = _.cloneDeep(state);
  newState.chat.form.classrooms.data = newList;
  newState.chat.form.classrooms.pagination.totalPages = action.meta.totalPages;
  newState.chat.form.classrooms.isLoading = false;

  return newState;
};

export const fetchNewChatStudentsRequest = (
  state: OmniChannelReducerProps,
  action: FetchNewChatStudentsRequestActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.students.isLoading = action.page === 1;
  newState.chat.form.students.pagination.page = action.page;

  return newState;
};

export const fetchNewChatStudentsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchNewChatStudentsActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.students.data =
    action.meta.page === 1
      ? action.students
      : [...state.chat.form.students.data, ...action.students];
  newState.chat.form.students.pagination.totalPages = action.meta.totalPages;
  newState.chat.form.students.isLoading = false;

  return newState;
};

export const fetchNewChatFamilyRequest = (
  state: OmniChannelReducerProps,
  action: FetchNewChatFamilyRequestActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.family.isLoading = action.page === 1;
  newState.chat.form.family.pagination.page = action.page;

  return newState;
};

export const fetchNewChatFamilySuccess = (
  state: OmniChannelReducerProps,
  action: FetchNewChatFamilyActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.family.data =
    action.meta.page === 1
      ? action.family
      : [...state.chat.form.family.data, ...action.family];
  newState.chat.form.family.pagination.totalPages = action.meta.totalPages;
  newState.chat.form.family.isLoading = false;

  return newState;
};

export const fetchNewChatResponsiblesRequest = (
  state: OmniChannelReducerProps,
  action: FetchNewChatResponsiblesRequestActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.responsibles.isLoading = action.page === 1;
  newState.chat.form.responsibles.pagination.page = action.page;

  return newState;
};

export const fetchNewChatResponsiblesSuccess = (
  state: OmniChannelReducerProps,
  action: FetchNewChatResponsiblesActionProps
) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.responsibles.data =
    action.meta.page === 1
      ? action.responsibles
      : [...state.chat.form.responsibles.data, ...action.responsibles];
  newState.chat.form.responsibles.pagination.totalPages =
    action.meta.totalPages;
  newState.chat.form.responsibles.isLoading = false;

  return newState;
};

export const fetchApprovedMessageSuccess = (
  state: OmniChannelReducerProps,
  action: FetchApprovedMessageSuccessAction
) => {
  const { message } = action;

  const { chats, activeChat } = normalizeFetchMessageReducer(
    state.chats,
    action.message
  );
  const newMessages = state.messages?.filter(
    (stateMessage) => stateMessage.id !== message.id
  );

  return {
    ...state,
    messages: [message, ...newMessages],
    chats,
    activeChat,
  };
};

export const fetchUnapprovedMessageSuccess = (
  state: OmniChannelReducerProps,
  action: FetchUnapprovedMessageSuccessAction
) => {
  const { message } = action;

  const newMessages = state.messages?.map((stateMessage) => {
    if (stateMessage.id === message.id) return message;
    return stateMessage;
  });

  return {
    ...state,
    messages: newMessages,
  };
};

export const fetchMessagesSuccess = (
  state: OmniChannelReducerProps,
  action: FetchMessagesSuccessAction
) => {
  const newMessages: Message[] = [];

  action.messages?.map((message) => {
    if (message.relationships.attachedMessage.data) {
      action.included.find((attachedMessage) => {
        if (
          attachedMessage.id === message.relationships.attachedMessage.data.id
        ) {
          newMessages.push({
            ...message,
            relationships: {
              ...message.relationships,
              attachedMessage: {
                data: attachedMessage,
              },
            },
          });
        }
      });
    } else {
      newMessages.push(message);
    }
  });

  const messagesList =
    action.page === 1 ? newMessages : [...state.messages, ...newMessages];

  return {
    ...state,
    activeChat: {
      ...state.activeChat,
      attributes: {
        ...state.activeChat?.attributes,
        read_status: action.meta.chat.attributes.read_status,
        student_profile_id: action.meta.chat.attributes.student_profile_id,
        student_profile_name: action.meta.chat.attributes.student_profile_name,
      },
    },
    messages: messagesList,
    messagesPagination: {
      itemsCountPerPage: action.itemsCountPerPage,
      totalItemsCount: action.totalItemsCount,
      page: action.page,
    },
    isLoadingMessages: false,
  };
};

export const fetchTicketsRequest = (
  state: OmniChannelReducerProps,
  action: FetchTicketsRequestActionProps
) => ({
  ...state,
  chats: action.params.page === 1 ? null : state.chats,
});

export const fetchTicketsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchTicketsSuccessActionProps
) => {
  const { chatList } = action;

  let newChats = [];
  let newActiveTicket = state.activeTicket;
  let newActiveChat = state.activeChat;

  if (chatList.length) {
    newChats = action.page === 1 ? chatList : [...state.chats, ...chatList];
  } else {
    newActiveTicket = null;
    newActiveChat = null;
  }

  return {
    ...state,
    activeTicket: newActiveTicket,
    activeChat: newActiveChat,
    chats: newChats,
    pagination: {
      ...state.pagination,
      page: action.page || 1,
      totalPages: action.totalPages || 0,
    },
  };
};

export const fetchTicketDetailRequest = (state: OmniChannelReducerProps) => ({
  ...state,
});

export const fetchTicketDetailSuccess = (
  state: OmniChannelReducerProps,
  action: FetchTicketDetailSuccessActionProps
) => ({
  ...state,
  activeTicket: {
    ...action.currentTicket,
    attributes: {
      ...state.activeTicket.attributes,
      ...action.currentTicket.attributes,
    },
  },
});

export const fetchTicketPendingCountRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
});

export const fetchTicketPendingCountSuccess = (
  state: OmniChannelReducerProps,
  action: FetchTicketPendingCountSuccessActionProps
) => {
  const isCurrentChannel = action.channelId === state.activeChannel.id;

  const newList = state.channels?.map((channel) => {
    if (channel.id === action.channelId) {
      return {
        ...channel,
        attributes: {
          ...channel.attributes,
          pending_action_count:
            action.pendingCount.inAttendanceTickets +
            action.pendingCount.waitingTickets,
        },
      };
    }

    return channel;
  });

  return {
    ...state,
    chatPendingCount: isCurrentChannel && action.pendingCount,
    channels: newList,
  };
};

export const fetchUnreadMessagesRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isLoading: true,
});

export const fetchTicketStudentChannelsRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
});

export const fetchTicketStudentChannelsSuccess = (
  state: OmniChannelReducerProps,
  action: FetchTicketStudentChannelsActionProps
) => {
  const ticketChannels: ChannelOption[] = action.channels
    ?.filter((channel: Channel) => channel.id !== state.activeChannel.id)
    .map((channel) => ({
      label: channel.attributes.name,
      value: channel.id,
      icon: channel.attributes.icon_url,
      iconTag: 'headphone-2',
    }));

  return {
    ...state,
    ticketStudentChannels: ticketChannels.reverse(),
  };
};

export const fetchUnreadMessagesSuccess = (
  state: OmniChannelReducerProps,
  action: FetchUnreadMessagesSuccessActionProps
) => ({
  ...state,
  isLoading: false,
  unreadCount: {
    chats: action.unreadChats,
    total: action.unreadCount,
  },
});

export const setApproveMessageRequest = (state: OmniChannelReducerProps) => ({
  ...state,
});

export const setApproveMessageSuccess = (
  state: OmniChannelReducerProps,
  action: SetApproveMessageSuccessActionProps
) => {
  let newMessagesList = [];

  const newMessage = {
    ...action.message,
    relationships: {
      attachedMessage: {
        data: action.included,
      },
    },
  };

  if (action.approveStatus === 'approve') {
    const messagesFilter = state.messages?.filter(
      (message) => message.id !== action.message.id
    );

    newMessagesList = [newMessage, ...messagesFilter];
  } else {
    newMessagesList = state.messages?.map((message) => {
      if (message.id === action.message.id) return newMessage;

      return message;
    });
  }

  return {
    ...state,
    messages: newMessagesList,
  };
};

export const setChatCurrentTab = (
  state: OmniChannelReducerProps,
  action: SetChatCurrentTabActionProps
) => ({
  ...state,
  chatCurrentTab: action.tab,
  pagination: {
    itemsPerPage: 0,
    page: 1,
    totalItemsCount: 0,
    totalPages: 1,
  },
});

export const setChatSearchCurrentTab = (
  state: OmniChannelReducerProps,
  action: SetChatSearchCurrentTabActionProps
) => ({
  ...state,
  chatSearchCurrentTab: action.tab,
});

export const setChatFilters = (
  state: OmniChannelReducerProps,
  action: SetChatFilterParamsActionProps
): OmniChannelReducerProps => {
  let amountFilters = 0;

  const currentChatFilters = [
    action.filters.isInactiveChats,
    action.filters.studentStatus,
    action.filters.headquarterId,
    action.filters.educationalStageId,
    action.filters.classroomId,
    action.filters.source,
    action.filters.subjectId,
    action.filters.chatKind,
  ];

  currentChatFilters.forEach((currentFilter) => {
    if (currentFilter) amountFilters += 1;
  });

  return {
    ...state,
    chatFilters: {
      isInactiveChats: action.filters.isInactiveChats,
      studentStatus: action.filters.studentStatus,
      headquarterId: action.filters.headquarterId,
      educationalStageId: action.filters.educationalStageId,
      classroomId: action.filters.classroomId,
      source: action.filters.source,
      amount: amountFilters,
      searchName: action.filters.searchName,
      subjectId: action.filters.subjectId,
      chatKind: action.filters.chatKind,
    },
  };
};

export const setDeleteChannelRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isLoading: true,
});

export const setDeleteChannelSuccess = (
  state: OmniChannelReducerProps,
  action: SetDeleteChannelActionProps
) => {
  const { removedChannel } = action;

  if (
    state.channels.length === 1 &&
    state.channels[0].id === removedChannel.id
  ) {
    return {
      ...state,
      channels: null,
      activeChannel: null,
    };
  }
  const updatedChannelList = state.channels?.filter(
    (channel) => channel.id !== removedChannel.id
  );

  const activeChannel = normalizeActiveChannel(updatedChannelList[0]);

  return {
    ...state,
    isLoading: false,
    channels: updatedChannelList,
    activeChannel,
  };
};

export const setDeleteMessageRequest = (state: OmniChannelReducerProps) => ({
  ...state,
});

export const setDeleteMessageSuccess = (
  state: OmniChannelReducerProps,
  action: SetDeleteMessageParamsActionProps
) => {
  const newMessages = state.messages?.map((message) => {
    if (message.id === action.message.id) {
      return action.message;
    }
    return message;
  });

  let newChats: Chat[];

  if (!state.chatIsSearchTab && state.messages[0].id === action.message.id) {
    const currentChat = state.chats.find(
      (chat) => chat.id === action.message.relationships.chat.data.id
    );

    newChats = state.chats?.map((chat) => {
      if (chat.id === currentChat.id) {
        return {
          ...currentChat,
          attributes: {
            ...currentChat.attributes,
            last_message_preview: action.message.attributes.text,
          },
        };
      }
      return chat;
    });
  } else {
    newChats = state.chats;
  }

  return {
    ...state,
    messages: newMessages,
    chats: newChats,
    isEditingMessage: false,
    isReplyMessage: false,
  };
};

export const setEditMessageRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isLoadingEditMessage: true,
});

export const setEditMessageSuccess = (
  state: OmniChannelReducerProps,
  action: SetEditMessageSuccessActionProps
) => {
  const newMessage = {
    ...action.message,
    relationships: {
      attachedMessage: {
        data: action?.included,
      },
    },
  };

  let newMessages = [];

  const isRejectedApproval = state.messages?.some((message) => {
    return (
      message.id === action.message.id &&
      message.attributes.approvalStatus === 'rejected'
    );
  });

  if (isRejectedApproval) {
    const newList = state.messages?.filter(
      (message) => message.id !== action.message.id
    );

    newMessages = [newMessage, ...newList];
  } else {
    newMessages = state.messages?.map((message) => {
      if (message.id === action.message.id) {
        return newMessage;
      }
      return message;
    });
  }

  let newChats: Chat[];

  const currentChat = state.chats?.find(
    (chat) => chat.id === action.message.relationships.chat.data.id
  );

  if (
    !state.chatIsSearchTab &&
    (state.messages[0].id === action.message.id || isRejectedApproval) &&
    action.message.attributes.approvalStatus !== 'pending' &&
    currentChat
  ) {
    newChats = state.chats?.map((chat) => {
      if (chat.id === currentChat.id) {
        return {
          ...currentChat,
          attributes: {
            ...currentChat.attributes,
            last_message_preview: action.message.attributes.text,
            last_message_sent_at: action.message.attributes.sentAt,
          },
        };
      }
      return chat;
    });
  } else {
    newChats = state.chats;
  }
  return {
    ...state,
    chats: newChats,
    messages: newMessages,
    currentMessage: null,
    isEditingMessage: false,
    isLoadingEditMessage: false,
  };
};

export const setEditChannelRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  isSubmittingChannelForm: true,
});

export const setEditChannelSuccess = (state: OmniChannelReducerProps) => ({
  ...state,
  channel: null,
  channels: null,
  isSubmittingChannelForm: false,
  avatarIconsList: null,
});

export const setRedirectMessageRequest = (state: OmniChannelReducerProps) => ({
  ...state,
  showRedirectMessageModal: false,
  currentMessage: null,
  isRedirectMessage: true,
});

export const setRedirectMessageSuccess = (
  state: OmniChannelReducerProps,
  action: SetRedirectMessageSuccessActionProps
) => {
  const hasMessageBot = state.messages?.find(
    (message) => action.newMessage.id === message.id
  );

  if (!state.chats || !state.messages || hasMessageBot) return;

  const { chats, messages } = normalizeRedirectMessage({
    chats: state.chats,
    messages: state.messages,
    messageBot: action.newMessage,
  });

  return {
    ...state,
    chats,
    messages,
    isRedirectMessage: false,
  };
};

export const setReplyMessageSuccess = (state: OmniChannelReducerProps) => ({
  ...state,
  isReplyMessage: false,
});

export const setStartOrCloseAttendanceRequest = (
  state: OmniChannelReducerProps
) => ({
  ...state,
  isLoadingMessages: true,
});

export const setStartOrCloseAttendanceSuccess = (
  state: OmniChannelReducerProps,
  action: SetStartOrCloseAttendanceStatusProps
) => {
  const newChats = normalizeChatTicket(state.chats, action.inAttendanceTicket);

  return {
    ...state,
    activeTicket: {
      ...state.activeTicket,
      attributes: {
        ...state.activeTicket.attributes,
        status: action.inAttendanceTicket.attributes.status,
      },
    },
    messages: [
      action.inAttendanceTicket.attributes.chat.lastBotMessage,
      ...state.messages,
    ],
    isLoadingMessages: false,
    chatCurrentTab:
      action.inAttendanceTicket.attributes.status === 'in_attendance' ? 1 : 2,
    chats: newChats,
  };
};

export const setTransferTicketRequest = (state: OmniChannelReducerProps) => {
  return {
    ...state,
  };
};

export const setTransferTicketSuccess = (
  state: OmniChannelReducerProps,
  action: SetTransferTicketActionProps
) => {
  const channels = state.channels?.map((channel) => {
    if (channel.id === state.activeChannel.id) {
      return {
        ...channel,
        attributes: {
          ...channel.attributes,
          pending_action_count: channel.attributes.pending_action_count - 1,
        },
      };
    }
    return channel;
  });

  const findedChannelIndex = channels?.findIndex(
    (channel) => channel?.id === action?.channelId
  );

  const findedCurrentChannelIndex = channels.findIndex(
    (currentChannel) => currentChannel.id === state.activeChannel.id
  );

  const activeChannel = normalizeActiveChannel(
    channels[
      findedChannelIndex >= 0 ? findedChannelIndex : findedCurrentChannelIndex
    ]
  );

  return {
    ...state,
    activeChannel,
    channels,
    chatCurrentTab: 0,
    activeChat: null,
  };
};

export const socketConnectSuccess = (
  state: OmniChannelReducerProps,
  action: SocketConnectSuccessActionProps
) => ({
  ...state,
  socket: action.socket,
});

export const toggleAttachmentModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showAttachmentModal: !state.showAttachmentModal,
});

export const toggleChatIsSearchTab = (state: OmniChannelReducerProps) => ({
  ...state,
  activeChat: null,
  chatSearchUsers: null,
  chatIsSearchTab: !state.chatIsSearchTab,
  chats: [],
  messages: null,
  activeChatUser: null,
  isNewChat: !state.chatIsSearchTab && false,
});

export const toggleDeleteChannelModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showDeleteChannelModal: !state.showDeleteChannelModal,
});

export const toggleEditMessage = (state: OmniChannelReducerProps) => ({
  ...state,
  isReplyMessage: false,
  isEditingMessage: !state.isEditingMessage,
});

export const toggleFilterChatlModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showFilterChatModal: !state.showFilterChatModal,
});

export const toggleNewChannelModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showNewChannelModal: !state.showNewChannelModal,
});

export const toggleReplyMessage = (state: OmniChannelReducerProps) => ({
  ...state,
  isEditingMessage: false,
  isReplyMessage: !state.isReplyMessage,
});

export const toggleResetActiveChannel = (state: OmniChannelReducerProps) => ({
  ...state,
  activeChannel: null,
});

export const toggleResetActiveChat = (state: OmniChannelReducerProps) => ({
  ...state,
  activeChat: null,
  activeChatUser: null,
});

export const toggleRedirectMessageModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showRedirectMessageModal: !state.showRedirectMessageModal,
});

export const toggleReportMessagesModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showReportMessagesModal: !state.showReportMessagesModal,
});

export const toggleSideDetailsModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showSideDetailsModal: !state.showSideDetailsModal,
});

export const toggleTransferTicketModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showTransferTicketModal: !state.showTransferTicketModal,
});

export const toggleChatDiscardModal = (state: OmniChannelReducerProps) => {
  const newState = _.cloneDeep(state);
  newState.chat.form.showDiscardModal = !newState.chat.form.showDiscardModal;
  return newState;
};

export const toggleOpeningHoursModal = (state: OmniChannelReducerProps) => ({
  ...state,
  showOpeningHoursModal: !state.showOpeningHoursModal,
});

const HANDLERS = {
  [actions.ERROR]: handlerError,
  [actions.FETCH_CHANNEL_REQUEST]: fetchChannelRequest,
  [actions.FETCH_CHANNEL_SUCCESS]: fetchChannelSuccess,
  [actions.FETCH_CHANNEL_SUBJECTS_REQUEST]: fetchChannelSubjectsRequest,
  [actions.FETCH_CHANNEL_SUBJECTS_SUCCESS]: fetchChannelSubjectsSuccess,
  [actions.FETCH_CHANNELS_REQUEST]: fetchChannelsRequest,
  [actions.FETCH_CHANNELS_SUCCESS]: fetchChannelsSuccess,
  [actions.FETCH_CHANNELS_BY_STUDENT_PROFILE_REQUEST]:
    fetchChannelsByStudentProfileRequest,
  [actions.FETCH_CHANNELS_BY_STUDENT_PROFILE_SUCCESS]:
    fetchChannelsByStudentProfileSuccess,
  [actions.FETCH_CHAT_CLASSROOMS_REQUEST]: fetchChatClassroomsRequest,
  [actions.FETCH_CHAT_CLASSROOMS_SUCCESS]: fetchChatClassroomsSuccess,
  [actions.FETCH_CHAT_CONFIRMED_RESPONSIBLES_REQUEST]:
    fetchChatConfirmedResponsiblesRequest,
  [actions.FETCH_CHAT_CONFIRMED_RESPONSIBLES_SUCCESS]:
    fetchChatConfirmedResponsiblesSuccess,
  [actions.FETCH_CHAT_PENDING_COUNT_REQUEST]: fetchChatPendingCountRequest,
  [actions.FETCH_CHAT_PENDING_COUNT_SUCCESS]: fetchChatPendingCountSuccess,
  [actions.FETCH_CHAT_STUDENT_PROFILES_REQUEST]:
    fetchChatStudentProfilesRequest,
  [actions.FETCH_CHAT_STUDENT_PROFILES_SUCCESS]:
    fetchchatStudentProfilesSuccess,
  [actions.FETCH_CHATS_REQUEST]: fetchChatsRequest,
  [actions.FETCH_CHATS_SUCCESS]: fetchChatsSuccess,
  [actions.FETCH_CHANNEL_FORM_REQUEST]: fetchChannelFormRequest,
  [actions.FETCH_CHANNEL_FORM_SUCCESS]: fetchChannelFormSuccess,
  [actions.FETCH_CURRENT_CHAT_SUCCESS]: fetchCurrentChatSuccess,
  [actions.FETCH_DELETED_MESSAGE_SUCCESS]: fetchDeletedMessageSuccess,
  [actions.FETCH_EDIT_MESSAGE_SUCCESS]: fetchEditMessageSuccess,
  [actions.FETCH_INITIAL_CHANNEL_FORM_REQUEST]: fetchInitialChannelFormRequest,
  [actions.FETCH_INITIAL_CHANNEL_FORM_SUCCESS]: fetchInitialChannelFormSuccess,
  [actions.FETCH_APPROVED_MESSAGE_SUCCESS]: fetchApprovedMessageSuccess,
  [actions.FETCH_MESSAGE_SUCCESS]: fetchMessageSuccess,
  [actions.FETCH_MESSAGES_REQUEST]: fetchMessagesRequest,
  [actions.FETCH_MESSAGES_SUCCESS]: fetchMessagesSuccess,
  [actions.FETCH_NEW_CHAT_CLASSROOMS_REQUEST]: fetchNewChatClassroomsRequest,
  [actions.FETCH_NEW_CHAT_CLASSROOMS_SUCCESS]: fetchNewChatClassroomsSuccess,
  [actions.FETCH_NEW_CHAT_FAMILY_REQUEST]: fetchNewChatFamilyRequest,
  [actions.FETCH_NEW_CHAT_FAMILY_SUCCESS]: fetchNewChatFamilySuccess,
  [actions.FETCH_NEW_CHAT_RESPONSIBLES_REQUEST]:
    fetchNewChatResponsiblesRequest,
  [actions.FETCH_NEW_CHAT_RESPONSIBLES_SUCCESS]:
    fetchNewChatResponsiblesSuccess,
  [actions.FETCH_NEW_CHAT_STUDENTS_REQUEST]: fetchNewChatStudentsRequest,
  [actions.FETCH_NEW_CHAT_STUDENTS_SUCCESS]: fetchNewChatStudentsSuccess,
  [actions.FETCH_SCHOOL_USERS_REQUEST]: fetchSchoolUsersRequest,
  [actions.FETCH_SCHOOL_USERS_SUCCESS]: fetchSchoolUsersSuccess,
  [actions.FETCH_CHAT_SEARCH_USERS_REQUEST]: fetchChatSearchUsersRequest,
  [actions.FETCH_CHAT_SEARCH_USERS_SUCCESS]: fetchChatSearchUsersSuccess,
  [actions.FETCH_TICKET_DETAIL_REQUEST]: fetchTicketDetailRequest,
  [actions.FETCH_TICKET_DETAIL_SUCCESS]: fetchTicketDetailSuccess,
  [actions.FETCH_TICKET_PENDING_COUNT_REQUEST]: fetchTicketPendingCountRequest,
  [actions.FETCH_TICKET_PENDING_COUNT_SUCCESS]: fetchTicketPendingCountSuccess,
  [actions.FETCH_TICKET_STUDENT_CHANNELS_REQUEST]:
    fetchTicketStudentChannelsRequest,
  [actions.FETCH_TICKET_STUDENT_CHANNELS_SUCCESS]:
    fetchTicketStudentChannelsSuccess,
  [actions.FETCH_TICKETS_REQUEST]: fetchTicketsRequest,
  [actions.FETCH_TICKETS_SUCCESS]: fetchTicketsSuccess,
  [actions.FETCH_UNAPPROVED_MESSAGE_SUCCESS]: fetchUnapprovedMessageSuccess,
  [actions.FETCH_UNREAD_CHATS_REQUEST]: fetchUnreadMessagesRequest,
  [actions.FETCH_UNREAD_CHATS_SUCCESS]: fetchUnreadMessagesSuccess,
  [actions.SET_APPROVE_MESSAGE_REQUEST]: setApproveMessageRequest,
  [actions.SET_APPROVE_MESSAGE_SUCCESS]: setApproveMessageSuccess,
  [actions.SET_CHAT_CURRENT_TAB]: setChatCurrentTab,
  [actions.SET_CHAT_SEARCH_CURRENT_TAB]: setChatSearchCurrentTab,
  [actions.SET_CHAT_FILTERS]: setChatFilters,
  [actions.SET_CREATE_NEW_CHANNEL_REQUEST]: setCreateNewChannelRequest,
  [actions.SET_CREATE_NEW_CHANNEL_SUCCESS]: setCreateNewChannelSuccess,
  [actions.SET_CREATE_OLD_NEW_CHAT_REQUEST]: setCreateOldNewChatRequest,
  [actions.SET_CREATE_OLD_NEW_CHAT_SUCCESS]: setCreateOldNewChatSuccess,
  [actions.SET_CREATE_NEW_CHAT_REQUEST]: setCreateNewChatRequest,
  [actions.SET_CREATE_NEW_CHAT_SUCCESS]: setCreateNewChatSuccess,
  [actions.SET_CREATE_NEW_CHAT_USER_SUCCESS]: setCreateNewChatUserSuccess,
  [actions.SET_CREATE_NEW_MESSAGE_REQUEST]: setCreateNewMessageRequest,
  [actions.SET_CREATE_NEW_MESSAGE_SUCCESS]: setCreateNewMessageSuccess,
  [actions.SET_CREATE_NEW_TICKET_BY_SCHOOL_REQUEST]:
    setCreateNewTicketBySchoolRequest,
  [actions.SET_CREATE_NEW_TICKET_BY_SCHOOL_SUCCESS]:
    setCreateNewTicketBySchoolSuccess,
  [actions.SET_CURRENT_CHANNEL]: setCurrentChannel,
  [actions.SET_CURRENT_CHAT]: setCurrentChat,
  [actions.SET_CURRENT_CHAT_USER]: setCurrentChatUser,
  [actions.SET_CURRENT_MESSAGE]: setCurrentMessage,
  [actions.SET_CHAT_FORM_FILTERS]: setChatFormFilters,
  [actions.SET_DELETE_CHANNEL_REQUEST]: setDeleteChannelRequest,
  [actions.SET_DELETE_CHANNEL_SUCCESS]: setDeleteChannelSuccess,
  [actions.SET_DELETE_MESSAGE_REQUEST]: setDeleteMessageRequest,
  [actions.SET_DELETE_MESSAGE_SUCCESS]: setDeleteMessageSuccess,
  [actions.SET_EDIT_CHANNEL_REQUEST]: setEditChannelRequest,
  [actions.SET_EDIT_CHANNEL_SUCCESS]: setEditChannelSuccess,
  [actions.SET_EDIT_MESSAGE_REQUEST]: setEditMessageRequest,
  [actions.SET_EDIT_MESSAGE_SUCCESS]: setEditMessageSuccess,
  [actions.SET_MESSAGES_MARK_READ_STATUS_REQUEST]:
    setMessagesMarkReadStatusRequest,
  [actions.SET_MESSAGES_MARK_READ_STATUS_SUCCESS]:
    setMessagesMarkReadStatusSuccess,
  [actions.SET_UPDATE_CHANNELS_ORDER_REQUEST]: setUpdateChannelsOrderRequest,
  [actions.SET_REDIRECT_CHANNEL_PAGE_SUCCESS]: setRedirectChannelPageSuccess,
  [actions.SET_REPLY_MESSAGE_SUCCESS]: setReplyMessageSuccess,
  [actions.SET_REDIRECT_MESSAGE_REQUEST]: setRedirectMessageRequest,
  [actions.SET_REDIRECT_MESSAGE_SUCCESS]: setRedirectMessageSuccess,
  [actions.SET_START_OR_CLOSE_ATTENDANCE_REQUEST]:
    setStartOrCloseAttendanceRequest,
  [actions.SET_START_OR_CLOSE_ATTENDANCE_SUCCESS]:
    setStartOrCloseAttendanceSuccess,
  [actions.SET_TRANSFER_TICKET_REQUEST]: setTransferTicketRequest,
  [actions.SET_TRANSFER_TICKET_SUCCESS]: setTransferTicketSuccess,
  [actions.SOCKET_CONNECT_SERVER_SUCCESS]: socketConnectSuccess,
  [actions.TOGGLE_ATTACHMENT_MODAL]: toggleAttachmentModal,
  [actions.TOGGLE_CHAT_IS_SEARCH_TAB]: toggleChatIsSearchTab,
  [actions.TOGGLE_CHAT_DISCARD_MODAL]: toggleChatDiscardModal,
  [actions.TOGGLE_DELETE_CHANNEL_MODAL]: toggleDeleteChannelModal,
  [actions.TOGGLE_EDIT_MESSAGE]: toggleEditMessage,
  [actions.TOGGLE_FILTER_CHAT_MODAL]: toggleFilterChatlModal,
  [actions.TOGGLE_NEW_CHANNEL_MODAL]: toggleNewChannelModal,
  [actions.TOGGLE_REPLY_MESSAGE]: toggleReplyMessage,
  [actions.TOGGLE_RESET_ACTIVE_CHANNEL]: toggleResetActiveChannel,
  [actions.TOGGLE_RESET_ACTIVE_CHAT]: toggleResetActiveChat,
  [actions.TOGGLE_REDIRECT_MESSAGE_MODAL]: toggleRedirectMessageModal,
  [actions.TOGGLE_REPORT_MESSAGES_MODAL]: toggleReportMessagesModal,
  [actions.TOGGLE_SIDE_DETAILS_MODAL]: toggleSideDetailsModal,
  [actions.TOGGLE_TRANSFER_TICKET_MODAL]: toggleTransferTicketModal,
  [actions.TOGGLE_PICKER_OPENING_HOURS_MODAL]: toggleOpeningHoursModal,
};

const omniChannel = createModuleReducer(INITIAL_STATE, HANDLERS);

export default omniChannel;
