import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  DefaultThemeProps,
  Modal,
} from '@agendaedu/ae-web-components';

import DiaryActions from 'store/dailySummaries/actions';
import withAppContext from 'core/hoc/withAppContext';

import { ShowSectionModalProps } from './types';

import * as S from './styles';

import {
  BabyBottleCard,
  ShowerCard,
  DiaperCard,
  EvacuationCard,
  MealCard,
  ObservationCard,
  OccurrenceCard,
  SleepCard,
  CustomCard,
} from './Sections';
import { useDispatch, useSelector } from 'react-redux';
import { DiaryState } from 'store/dailySummaries/types';
import { DiarySectionSkeleton } from './skeleton';
import {
  defaultAnswersAttributesValues,
  defaultBabyBottles,
  defaultEvacuations,
  defaultMeal,
  defaultOccurrence,
  defaultSleeps,
} from 'core/contexts/DiaryForm/constants';
import { useTheme } from 'styled-components';

const ShowSectionModal = ({
  isOpen,
  section,
  onClose,
  onDelete,
  onEdit,
  appContext: {
    policies: {
      can_edit_sections: canEditSections,
      can_delete_sections: canDeleteSections,
    },
  },
}: ShowSectionModalProps) => {
  const { t } = useTranslation(['diary_sections', 'common']);
  const { breakpoints } = useTheme() as DefaultThemeProps;
  const { fetchDiarySectionFormRequest } = DiaryActions;
  const dispatch = useDispatch();
  const {
    diarySections: { diarySectionForm: customSection, isLoadingCurrentSection },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const isCustom = section.attributes.custom;

  const currentComponentSection: JSX.Element = {
    baby_bottle: (
      <BabyBottleCard
        onChange={() => false}
        babyBottles={defaultBabyBottles()}
      />
    ),
    shower: <ShowerCard onChange={() => false} shower={0} />,
    diaper: <DiaperCard onChange={() => false} diaper={0} />,
    evacuation: (
      <EvacuationCard
        onChange={() => false}
        evacuations={defaultEvacuations()}
      />
    ),
    meals: <MealCard onChange={() => false} meal={defaultMeal()} key={1} />,
    observation: <ObservationCard onChange={() => false} observation="" />,
    occurrence: (
      <OccurrenceCard onChange={() => false} occurrence={defaultOccurrence()} />
    ),
    sleep: <SleepCard onChange={() => false} sleeps={defaultSleeps()} />,
    custom: customSection && (
      <CustomCard
        section={customSection}
        onChange={() => false}
        answersAttributesList={customSection.questionsAttributes.map(
          (question) => defaultAnswersAttributesValues(String(question.id))
        )}
      />
    ),
  }[isCustom ? 'custom' : section.attributes.slug];

  useEffect(() => {
    if (isCustom) {
      dispatch(fetchDiarySectionFormRequest({ diarySectionId: section.id }));
    }
  }, [dispatch, fetchDiarySectionFormRequest, isCustom, section.id]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('sections.show_section')}>
      <S.ContentWrapper>
        {isLoadingCurrentSection ? (
          <DiarySectionSkeleton />
        ) : (
          <Box maxWidth={breakpoints.tabletMD}>{currentComponentSection}</Box>
        )}

        <S.FooterWrapper>
          <Button
            testId="cancel-show-btn"
            variant="secondary"
            onClick={onClose}
          >
            {t('common:button.cancel')}
          </Button>
          {canEditSections && (
            <Button
              testId="edit-section-btn"
              variant="secondary"
              onClick={onEdit}
            >
              {t('common:button.edit')}
            </Button>
          )}
          {canDeleteSections && (
            <Button
              testId="delete-section-btn"
              variant="secondary"
              contextVariant="alert"
              onClick={onDelete}
              disabled={!section.attributes.custom}
            >
              {t('common:button.delete')}
            </Button>
          )}
        </S.FooterWrapper>
      </S.ContentWrapper>
    </Modal>
  );
};

export default withAppContext(ShowSectionModal);
