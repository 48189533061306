import _ from 'lodash';

import createModuleReducer from '../storeConfig/createModuleReducer';
import { getCurrentDate } from 'core/utils/date';
import actions from './actions';
import {
  ActivesDiarySections,
  DiaryFormStructure,
  DiaryReportStructure,
  DiarySectionForm,
  DiaryState,
  StudentDiaryStructure,
} from './types';
import {
  defaultAnswersAttributesValues,
  defaultBabyBottles,
  defaultEvacuations,
  defaultSleeps,
} from 'core/contexts/DiaryForm/constants';

export const initialForm = () => ({
  meals: [
    {
      id: Math.random().toString(36).substring(7),
      category: '',
      description: '',
      notEat: false,
    },
  ],
  snacks: {
    breakfast: '',
    lunch: '',
    afternoonSnack: '',
    dinner: '',
  },
  babyBottles: {
    notDrink: false,
    quantities: [{ id: Math.random().toString(36).substring(7), quantity: '' }],
  },
  sleeps: {
    notSlept: false,
    periods: [{ id: Math.random().toString(36).substring(7), sleep: '' }],
  },
  evacuations: {
    notEvacuated: false,
    appearances: [
      { id: Math.random().toString(36).substring(7), evacuation: '' },
    ],
  },
  diaper: 0,
  bath: 0,
  observation: '',
  occurrences: [
    {
      id: Math.random().toString(36).substring(7),
      category: '',
      description: '',
    },
  ],
});

const normalizeDiaryToForm = ({
  data,
  included,
  state,
}: StudentDiaryStructure['response'] & {
  state: DiaryState['dailySummaries'];
}): DiaryState['dailySummaries']['studentsDiariesBySection']['studentsDiaries'] => {
  const generateKey = () => Math.random().toString(36).substring(7);

  return data.reduce((prevDiaries, diary) => {
    const conditions = diary && {
      meals: {
        shouldAdd: !!diary.attributes.meals.length,
      },
      baby_bottles: {
        shouldAdd:
          diary.attributes.baby_bottles.not_drink ||
          !!diary.attributes.baby_bottles.quantities.length,
        shouldAddQuantities: !!diary.attributes.baby_bottles.quantities.length,
      },
      sleeps: {
        shouldAdd:
          diary.attributes.sleeps.not_slept ||
          !!diary.attributes.sleeps.periods.length,
        shouldAddPeriods: !!diary.attributes.sleeps.periods.length,
      },
      evacuations: {
        shouldAdd:
          diary.attributes.evacuations.not_evacuated ||
          !!diary.attributes.evacuations.appearances.length,
        shouldAddAppearances: !!diary.attributes.evacuations.appearances.length,
      },
      occurrences: {
        shouldAdd: !!diary.attributes.occurrences.data.length,
      },
      answers_attributes: {
        shouldAdd: !!diary.relationships.answers.data.length,
      },
    };

    const studentDiaryForm: Partial<
      DiaryState['dailySummaries']['studentDiaryForm']
    > = {
      ...(conditions && {
        ...(conditions.meals.shouldAdd && {
          meals: diary.attributes.meals.map((meal) => ({
            key: generateKey(),
            ...meal,
          })),
        }),
        ...(conditions.baby_bottles.shouldAdd && {
          baby_bottles: {
            not_drink: diary.attributes.baby_bottles.not_drink,
            quantities: conditions.baby_bottles.shouldAddQuantities
              ? diary.attributes.baby_bottles.quantities
              : defaultBabyBottles().quantities,
          },
        }),
        ...(conditions.sleeps.shouldAdd && {
          sleeps: {
            not_slept: diary.attributes.sleeps.not_slept,
            periods: conditions.sleeps.shouldAddPeriods
              ? diary.attributes.sleeps.periods.map((sleep) => ({
                  key: generateKey(),
                  ...sleep,
                }))
              : defaultSleeps().periods,
          },
        }),
        ...(conditions.evacuations.shouldAdd && {
          evacuations: {
            not_evacuated: diary.attributes.evacuations.not_evacuated,
            appearances: conditions.evacuations.shouldAddAppearances
              ? diary.attributes.evacuations.appearances
              : defaultEvacuations().appearances,
          },
        }),
        ...(conditions.occurrences.shouldAdd && {
          occurrences: diary.attributes.occurrences.data.map((occurrence) => ({
            id: occurrence.id,
            key: generateKey(),
            category: occurrence.attributes.category,
            description: occurrence.attributes.description,
            files: occurrence.attributes.files.map((file) => {
              const fileStructure = {
                ...file,
                signed_id: undefined,
                fileId: file.signed_id,
                signedId: file.signed_id,
                invalidReasons: [],
              };

              delete fileStructure.signed_id;

              return fileStructure;
            }),
          })),
        }),
        shower: diary.attributes.shower,
        diaper: diary.attributes.diaper,
        observation: diary.attributes.observation,
        ...(conditions.answers_attributes.shouldAdd && {
          answers_attributes: state.diarySections.activesSections.reduce(
            (prevSections, currentSection) => {
              if (!currentSection.custom) return prevSections;

              const structuredGroups = included.reduce((prevGroups, item) => {
                const isQuestionFromSectionAndDiary =
                  item.type === 'diaryAnswer' &&
                  String(item.attributes.question.sectionId) ===
                    currentSection.id &&
                  String(item.attributes.dailySummaryId) === diary.id;

                if (isQuestionFromSectionAndDiary) {
                  const normalizedAnswersAttributes: DiaryFormStructure['answers_attributes_values'] =
                    {
                      key: generateKey(),
                      id: item.id,
                      section_group_id: item.attributes.sectionGroupId,
                      answer_text:
                        item.attributes.question.kind === 'text'
                          ? item.attributes.answerText
                          : '',
                      diary_answer_option_id:
                        item.attributes.question.kind === 'select'
                          ? String(item.attributes.answerOption.id)
                          : '',
                      diary_question_id: String(item.attributes.question.id),
                    };

                  const alreadyExistGroup =
                    !!prevGroups[item.attributes.sectionGroupId];

                  if (alreadyExistGroup) {
                    prevGroups[item.attributes.sectionGroupId].push(
                      normalizedAnswersAttributes
                    );
                  } else {
                    prevGroups[item.attributes.sectionGroupId] = [
                      normalizedAnswersAttributes,
                    ];
                  }

                  return prevGroups;
                }

                return prevGroups;
              }, {} as Record<string, DiaryFormStructure['answers_attributes_values'][]>);

              const groupsWithStructuredEmptyQuestions = Object.values(
                structuredGroups
              ).reduce((prevGroup, answers) => {
                const newAnswers = currentSection.questionsAttributes.reduce(
                  (prevAnswers, currentQuestion) => {
                    const hasOnGroup = answers.find(
                      (answer) =>
                        answer.diary_question_id === currentQuestion.id
                    );

                    if (hasOnGroup) return [...prevAnswers, hasOnGroup];

                    const normalizedValues = defaultAnswersAttributesValues(
                      currentQuestion.id
                    );

                    return [...prevAnswers, normalizedValues];
                  },
                  []
                );

                return [...prevGroup, newAnswers];
              }, []);

              return {
                ...prevSections,
                ...(!!groupsWithStructuredEmptyQuestions.length && {
                  [currentSection.id]: groupsWithStructuredEmptyQuestions,
                }),
              };
            },
            {}
          ),
        }),
      }),
    };

    return {
      ...prevDiaries,
      [diary.attributes.student_profile_id]: studentDiaryForm,
    };
  }, {});
};

export const INITIAL_STATE: Partial<DiaryState['dailySummaries']> &
  Record<string, unknown> = {
  dailySummaryId: null,
  currentStudent: {},
  classroomsList: [],
  classroomsDiarySections: [],
  classroomSections: [], //old
  sections: [], //old
  diarySections: {
    diarySectionId: null,
    sections: [],
    sectionsIncluded: [],
    isLoadingCurrentSection: false,
    isLoadingSections: false,
    isSubmittingSection: false,
    recipients: {
      initialAttributes: {
        sent_kind: 'classrooms',
      },
      formMeta: {
        hideRadio: true,
        hideSendTo: true,
        classrooms: [],
        canEditClassrooms: true,
      },
    },
    diarySectionForm: null,
    diarySectionData: null,
    activesSections: [],
  },
  classroom: {
    attributes: {
      name: '',
      student_profiles: { data: [] },
    },
  },
  classroomIncluded: [],
  isLoading: true,
  classroomPagination: {
    totalItemsCount: 0,
    currentPage: 1,
    itemsCountPerPage: null,
  },
  current: initialForm(),
  date: getCurrentDate(),
  isFetching: false,
  studentsIdsSelecteds: [], //old
  draftDailySummary: initialForm(),
  selectedHeadquarter: null,
  selectedEducationalStage: null,
  formMeta: {
    occurrencesList: [],
    headquarters: [],
    educationalStages: [],
    sectionSlugs: [],
    filterAmount: 0,
  },
  isSending: false, //old
  isLoadingStudentDiaryForm: false,
  studentDiaryForm: null,
  studentsDiariesBySection: {
    isLoading: false,
    studentsDiaries: null,
  },
  diaryReport: {
    isLoadingReportData: false,
    isLoadingReportSections: false,
    headquarters: [],
    classrooms: [],
    students: [],
    sections: [],
  },
};

export const fetchClassroomsList = (state, action) => ({
  ...state,
  isFetching: true,
  studentsIdsSelecteds: [],
  classroom: INITIAL_STATE.classroom,
  ...(action.filters.page === 1 && { classroomsList: [] }),
});

export const fetchClassroomsListSuccess = (state, action) => {
  const {
    data,
    pagination,
    filters: { headquarter, educational_stage, name, date },
  } = action;

  const newFilterAmount = () => {
    const actionFilters = Object.values({
      headquarter,
      educational_stage,
    }).filter((value) => Boolean(value)).length;

    const mainFilters = Object.values({
      name,
      date,
    }).filter((value) => Boolean(value)).length;

    if (mainFilters > 1) return state.formMeta.filterAmount;

    if (actionFilters > 0) return actionFilters;

    return 0;
  };

  const getName = () => {
    return name !== undefined ? name : state.selectedName;
  };

  return {
    ...state,
    isFetching: false,
    classroomsList:
      action.pagination.currentPage === 1
        ? data
        : [...state.classroomsList, ...data],
    selectedName: getName(),
    selectedHeadquarter: headquarter || state.selectedHeadquarter,
    selectedEducationalStage:
      educational_stage || state.selectedEducationalStage,
    formMeta: {
      ...state.formMeta,
      filterAmount: newFilterAmount(),
    },
    classroomPagination: pagination,
  };
};

export const handleDraftDailySummary = (state, action) => ({
  ...state,
  draftDailySummary: {
    ...state.draftDailySummary,
    [action.key]: action.value,
  },
});

export const addDailySummary = (state) => ({
  ...state,
  isSending: true,
});

export const addDailySummarySuccess = (state) => ({
  ...state,
  isSending: false,
  current: initialForm(),
  draftDailySummary: initialForm(),
  studentsIdsSelecteds: [],
});

export const fetchStudentSummary = (state) => ({
  ...state,
  isFetching: true,
});

export const fetchStudentSummarySuccess = (state, action) => {
  const { student, summary, meta, included } = action;

  const { id, attributes, relationships } = summary;

  const {
    attributes: {
      student_profiles: { data: studentProfilesList },
    },
  } = state.classroom;

  const formattedVersions = relationships
    ? relationships.versions.data.map((version) =>
        included.find(
          (includ) => version.id === includ.id && version.type === includ.type
        )
      )
    : [];

  const currentStudent = studentProfilesList.find(
    (profile) => profile.id === student
  );

  const attributesClone = _.cloneDeep(attributes);
  delete attributesClone?.sections;

  return {
    ...state,
    currentStudent: {
      ...currentStudent,
      summary: attributesClone,
      sections: attributes?.sections,
      included,
      versions: formattedVersions,
    },
    dailySummaryId: id,
    isFetching: false,
    formMeta: {
      ...state.formMeta,
      sectionSlugs: meta,
    },
  };
};

export const fetchClassroom = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchClassroomSucess = (state, action) => {
  const { classroom, classroomIncluded, occurrences } = action;

  const { classroom_sections: classroomSections } = classroom.attributes;

  const occurrenceCategories = occurrences.reduce((acc, [label, value]) => {
    return [...acc, { label, value }];
  }, []);

  return {
    ...state,
    isLoading: false,
    classroom,
    classroomIncluded,
    classroomSections,
    formMeta: {
      ...state.formMeta,
      occurrencesList: [
        { value: null, label: 'Selecionar' },
        ...occurrenceCategories,
      ],
    },
  };
};

export const setDate = (state, action) => ({
  ...state,
  date: action.date,
  current: initialForm(),
  draftDailySummary: initialForm(),
});

export const setHeadquarter = (state, action) => ({
  ...state,
  selectedHeadquarter: action.headquarter,
});

export const setEducationalStage = (state, action) => ({
  ...state,
  selectedEducationalStage: action.educationalStage,
});

export const fetchDailySummaryDetails = (state) => ({
  ...state,
  isFetching: true,
});

export const fetchDailySummaryDetailsSuccess = (state, action) => {
  if (!action.data) {
    return {
      ...state,
      isFetching: false,
    };
  }

  const {
    breakfast,
    lunch,
    afternoon_snack,
    dinner,
    diaper,
    shower,
    evacuations,
    baby_bottles,
    observation,
    sleeps,
    occurrences,
    meals,
  } = action.data.attributes;

  const insertIds = (list, keys) => {
    if (list?.length === 0) {
      return [
        {
          id: Math.random().toString(36).substring(7),
          ...keys.map((key) => ({ [key]: '' })),
        },
      ];
    } else {
      return list.map((item) => ({
        id: Math.random().toString(36).substring(7),
        ...item,
      }));
    }
  };

  const sleepFormat = sleeps.periods.map((period) => ({
    sleep: `${period.start_time} - ${period.end_time}`,
  }));

  const validValue = (fieldValue) => fieldValue || '';

  const formatOccurrences = () =>
    occurrences.data.length > 0
      ? occurrences.data.map(({ id, attributes }) => ({
          id,
          ...attributes,
        }))
      : [
          {
            id: Math.random().toString(36).substring(7),
            category: '',
            description: '',
          },
        ];

  const newCurrentForm = {
    ...initialForm(),
    snacks: {
      breakfast: validValue(breakfast),
      lunch: validValue(lunch),
      afternoonSnack: validValue(afternoon_snack),
      dinner: validValue(dinner),
    },
    diaper,
    bath: shower,
    evacuations: {
      notEvacuated: evacuations.not_evacuated,
      appearances: insertIds(evacuations.appearances, ['evacuation']),
    },
    babyBottles: {
      notDrink: baby_bottles.not_drink,
      quantities: insertIds(baby_bottles.quantities, ['quantity']),
    },
    observation,
    sleeps: {
      notSlept: sleeps.not_slept,
      periods: insertIds(sleepFormat, ['sleep']),
    },
    occurrences: formatOccurrences(),
    ...(!!meals.length && {
      meals: insertIds(
        meals?.map(({ category, description, not_ate }) => ({
          category,
          description,
          notEat: not_ate,
        })),
        ['category', 'description', 'notEat']
      ),
    }),
  };

  return {
    ...state,
    isFetching: false,
    current: newCurrentForm,
    draftDailySummary: newCurrentForm,
  };
};

export const setSelectedsIds = (state, action) => ({
  ...state,
  studentsIdsSelecteds: action.studentsIdsSelecteds,
  current: initialForm(),
  draftDailySummary: initialForm(),
});

export const fetchHeadquarters = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    headquarters: [],
  },
});

export const fetchHeadquartersSuccess = (state, action) => {
  const formattedHeadquarters = [{ value: null, label: 'Selecione a unidade' }];

  action.headquarters.forEach((headquarter) => {
    formattedHeadquarters.push({
      value: headquarter.id,
      label: headquarter.attributes.name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      headquarters: formattedHeadquarters,
    },
  };
};

export const fetchEducationalStages = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    educationalStages: [],
  },
});

export const fetchEducationalStagesSuccess = (state, action) => {
  const formattedEducationalStages = [
    { value: null, label: 'Selecione o segmento' },
  ];

  action.educationalStages.forEach((educationalStage) => {
    formattedEducationalStages.push({
      value: educationalStage.id,
      label: educationalStage.attributes.name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      educationalStages: formattedEducationalStages,
    },
  };
};

export const fetchSettings = (state) => ({
  ...state,
  isLoading: true,
  classroomsDiarySections: [],
});

export const fetchSettingsSuccess = (state, action) => {
  const { headquarters, sections } = action;
  const formatedHeadquarter = headquarters.map((headquarter) => ({
    ...headquarter,
    options: headquarter.options.map((classroom) => ({
      ...classroom,
      value: classroom.id,
      label: classroom.attributes.name,
    })),
  }));

  const formatedSections = sections.map(
    ({ id, attributes: { name, active_classrooms } }) => ({
      value: id,
      label: name,
      classrooms: active_classrooms,
    })
  );

  return {
    ...state,
    isLoading: false,
    classroomsDiarySections: formatedHeadquarter,
    sections: formatedSections,
  };
};

export const saveSettings = (state) => ({
  ...state,
  isSending: true,
});

export const saveSettingsSuccess = (state) => ({
  ...state,
  isSending: false,
});

export const handleError = (state, action) => ({
  ...state,
  isLoading: false,
  error: action.error,
});

export const fetchDiarySections = (state) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    isLoadingSections: true,
  },
});

export const fetchDiarySectionsSuccess = (state, action) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    sections: action.sections,
    sectionsIncluded: action.sectionsIncluded,
    isLoadingSections: false,
  },
});

export const fetchDiarySectionsError = (state) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    isLoadingSections: false,
  },
});

export const fetchRecipientsClassroomsSuccess = (
  state: DiaryState['dailySummaries'],
  action
) => {
  const { data } = action;
  const classrooms = data.map((classroom) => ({
    ...classroom,
    options: classroom.options.map((option) => ({
      ...option,
      label: option.attributes.name,
      value: option.id,
    })),
  }));

  return {
    ...state,
    diarySections: {
      ...state.diarySections,
      recipients: {
        ...state.diarySections.recipients,
        formMeta: {
          ...state.diarySections.recipients.formMeta,
          classrooms,
        },
      },
    },
  };
};

export const saveSectionRequest = (state) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    isSubmittingSection: true,
  },
});

export const saveSectionRequestSuccess = (state) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    isSubmittingSection: false,
  },
});

export const saveSectionRequestError = (state) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    isSubmittingSection: false,
  },
});

export const fetchDiarySectionFormRequest = (state) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    isLoadingCurrentSection: true,
    diarySectionForm: null,
    diarySectionData: null,
  },
});

export const fetchDiarySectionFormSuccess = (
  state,
  action: DiarySectionForm['sectionResponse']
) => {
  const classroomIds = action.included.reduce((ids, current) => {
    if (current.type === 'classroom') return [...ids, current.id];
    return ids;
  }, []);

  const questionsAttributes = action.data.relationships.questions.data.map(
    (question) => {
      const questionRelated = action.included.find(
        (current) =>
          current.type === 'diaryQuestion' && current.id === question.id
      ) as DiarySectionForm['diaryQuestion'];

      const answerOptionsAttributes = questionRelated.attributes.answerOptions;

      return {
        id: questionRelated.id,
        title: questionRelated.attributes.title,
        kind: questionRelated.attributes.kind,
        ...(questionRelated.attributes.kind === 'select' && {
          answerOptionsAttributes,
        }),
      };
    }
  );

  return {
    ...state,
    diarySections: {
      ...state.diarySections,
      isLoadingCurrentSection: false,
      diarySectionForm: {
        name: action.data.attributes.name,
        classroomIds,
        questionsAttributes,
      },
      diarySectionData: action.data,
    },
  };
};

export const deleteDiarySectionsSuccess = (
  state: DiaryState['dailySummaries'],
  action: { diarySectionId: string }
) => {
  const filteredSections = state.diarySections.sections.filter(
    (section) => section.id !== action.diarySectionId
  );

  return {
    ...state,
    diarySections: {
      ...state.diarySections,
      sections: filteredSections,
    },
  };
};

export const fetchActivesDiarySectionsRequest = (
  state: DiaryState['dailySummaries']
) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    activesSections: [],
  },
});

export const fetchActivesDiarySectionsError = (
  state: DiaryState['dailySummaries'],
  action
) => ({
  ...state,
  diarySections: {
    ...state.diarySections,
    activesSections: [],
  },
  error: action.error,
});

export const fetchActivesDiarySectionsSuccess = (
  state,
  action: ActivesDiarySections['response']
) => {
  const activesSections = action.data.reduce(
    (
      prevSections: ActivesDiarySections['structuredSection'][],
      currentSection
    ): ActivesDiarySections['structuredSection'][] => {
      const questionsAttributes =
        currentSection.relationships.questions.data.map((question) => {
          const questionRelated = action.included.find(
            (current) =>
              current.type === 'diaryQuestion' && current.id === question.id
          );

          const answerOptionsAttributes =
            questionRelated.attributes.answerOptions;

          return {
            id: questionRelated.id,
            title: questionRelated.attributes.title,
            kind: questionRelated.attributes.kind,
            ...(questionRelated.attributes.kind === 'select' && {
              answerOptionsAttributes,
            }),
          };
        });

      return [
        ...prevSections,
        {
          id: currentSection.id,
          custom: currentSection.attributes.custom,
          slug: currentSection.attributes.slug,
          name: currentSection.attributes.name,
          questionsAttributes,
        },
      ];
    },
    []
  );

  return {
    ...state,
    diarySections: {
      ...state.diarySections,
      activesSections,
    },
  };
};

export const fetchStudentDiaryRequest = (
  state: DiaryState['dailySummaries']
): DiaryState['dailySummaries'] => ({
  ...state,
  isLoadingStudentDiaryForm: true,
  studentDiaryForm: null,
});

export const fetchStudentDiarySuccess = (
  state: DiaryState['dailySummaries'],
  { data, included }: StudentDiaryStructure['response']
): DiaryState['dailySummaries'] => {
  const studentDiaryForm = normalizeDiaryToForm({ data, included, state });

  return {
    ...state,
    isLoadingStudentDiaryForm: false,
    studentDiaryForm: Object.values(studentDiaryForm)[0],
  };
};

export const fetchStudentDiaryError = (
  state: DiaryState['dailySummaries']
): DiaryState['dailySummaries'] => ({
  ...state,
  isLoadingStudentDiaryForm: false,
  studentDiaryForm: null,
});

export const fetchDiaryReportInitialRecipientsRequest = (
  state: DiaryState['dailySummaries']
): DiaryState['dailySummaries'] => ({
  ...state,
  diaryReport: {
    ...state.diaryReport,
    isLoadingReportData: true,
  },
});

export const fetchDiaryReportInitialRecipientsSuccess = (
  state: DiaryState['dailySummaries'],
  {
    classrooms,
    headquarters,
  }: Pick<DiaryReportStructure, 'headquarters' | 'classrooms'>
): DiaryState['dailySummaries'] => {
  return {
    ...state,
    diaryReport: {
      ...state.diaryReport,
      isLoadingReportData: false,
      classrooms,
      headquarters,
    },
  };
};

export const fetchDiaryReportStudentSuccess = (
  state: DiaryState['dailySummaries'],
  { students }: Pick<DiaryReportStructure, 'students'>
): DiaryState['dailySummaries'] => ({
  ...state,
  diaryReport: {
    ...state.diaryReport,
    students,
  },
});

export const fetchDiaryReportSectionsRequest = (
  state: DiaryState['dailySummaries']
): DiaryState['dailySummaries'] => ({
  ...state,
  diaryReport: {
    ...state.diaryReport,
    sections: [],
    isLoadingReportSections: true,
  },
});

export const fetchDiaryReportSectionsSuccess = (
  state: DiaryState['dailySummaries'],
  { sections }: Pick<DiaryReportStructure, 'sections'>
): DiaryState['dailySummaries'] => ({
  ...state,
  diaryReport: {
    ...state.diaryReport,
    sections,
    isLoadingReportSections: false,
  },
});

export const fetchStudentsDiariesForm = (
  state: DiaryState['dailySummaries']
): DiaryState['dailySummaries'] => ({
  ...state,
  studentsDiariesBySection: {
    ...state.studentsDiariesBySection,
    isLoading: true,
  },
});

export const fetchStudentsDiariesFormError = (
  state: DiaryState['dailySummaries']
): DiaryState['dailySummaries'] => ({
  ...state,
  studentsDiariesBySection: {
    ...state.studentsDiariesBySection,
    isLoading: false,
  },
});

export const fetchStudentsDiariesFormSuccess = (
  state: DiaryState['dailySummaries'],
  { data, included }: StudentDiaryStructure['response']
): DiaryState['dailySummaries'] => {
  const studentsDiaries = normalizeDiaryToForm({ data, included, state });

  return {
    ...state,
    studentsDiariesBySection: {
      isLoading: false,
      studentsDiaries,
    },
  };
};

const HANDLERS = {
  [actions.FETCH_CLASSROOMS_LIST]: fetchClassroomsList,
  [actions.FETCH_CLASSROOMS_LIST_SUCCESS]: fetchClassroomsListSuccess,
  [actions.HANDLE_DRAFT_DAILY_SUMMARY]: handleDraftDailySummary,
  [actions.ADD_DAILY_SUMMARY_REQUEST]: addDailySummary, //old
  [actions.ADD_DAILY_SUMMARY_SUCCESS]: addDailySummarySuccess, //old
  [actions.FETCH_STUDENT_SUMMARY_REQUEST]: fetchStudentSummary,
  [actions.FETCH_STUDENT_SUMMARY_SUCCESS]: fetchStudentSummarySuccess,
  [actions.FETCH_CLASSROOM_DETAILS]: fetchClassroom,
  [actions.FETCH_CLASSROOM_DETAILS_SUCCESS]: fetchClassroomSucess,
  [actions.SET_DATE]: setDate, // old
  [actions.SET_HEADQUARTER]: setHeadquarter,
  [actions.SET_EDUCATIONAL_STAGE]: setEducationalStage,
  [actions.FETCH_DAILY_SUMMARY_DETAILS_REQUEST]: fetchDailySummaryDetails,
  [actions.FETCH_DAILY_SUMMARY_DETAILS_SUCCESS]:
    fetchDailySummaryDetailsSuccess,
  [actions.SET_SELECTEDS_IDS]: setSelectedsIds,
  [actions.FETCH_HEADQUARTERS]: fetchHeadquarters,
  [actions.FETCH_HEADQUARTERS_SUCCESS]: fetchHeadquartersSuccess,
  [actions.FETCH_EDUCATIONAL_STAGES]: fetchEducationalStages,
  [actions.FETCH_EDUCATIONAL_STAGES_SUCCESS]: fetchEducationalStagesSuccess,
  [actions.FETCH_SETTINGS_REQUEST]: fetchSettings,
  [actions.FETCH_SETTINGS_SUCCESS]: fetchSettingsSuccess,
  [actions.SAVE_SETTINGS_REQUEST]: saveSettings,
  [actions.SAVE_SETTINGS_SUCCESS]: saveSettingsSuccess,
  [actions.FETCH_DIARY_SECTIONS]: fetchDiarySections,
  [actions.FETCH_DIARY_SECTIONS_SUCCESS]: fetchDiarySectionsSuccess,
  [actions.FETCH_DIARY_SECTIONS_ERROR]: fetchDiarySectionsError,
  [actions.FETCH_RECIPIENTS_CLASSROOMS_SUCCESS]:
    fetchRecipientsClassroomsSuccess,
  [actions.SAVE_SECTION_REQUEST]: saveSectionRequest,
  [actions.SAVE_SECTION_REQUEST_SUCCESS]: saveSectionRequestSuccess,
  [actions.SAVE_SECTION_REQUEST_ERROR]: saveSectionRequestError,
  [actions.FETCH_DIARY_SECTION_FORM_REQUEST_SUCCESS]:
    fetchDiarySectionFormSuccess,
  [actions.FETCH_DIARY_SECTION_FORM_REQUEST]: fetchDiarySectionFormRequest,
  [actions.DELETE_DIARY_SECTION_SUCCESS]: deleteDiarySectionsSuccess,
  [actions.FETCH_ACTIVES_DIARY_SECTIONS_REQUEST]:
    fetchActivesDiarySectionsRequest,
  [actions.FETCH_ACTIVES_DIARY_SECTIONS_SUCCESS]:
    fetchActivesDiarySectionsSuccess,
  [actions.FETCH_ACTIVES_DIARY_SECTIONS_ERROR]: fetchActivesDiarySectionsError,
  [actions.FETCH_STUDENT_DIARY_FORM]: fetchStudentDiaryRequest,
  [actions.FETCH_STUDENT_DIARY_FORM_SUCCESS]: fetchStudentDiarySuccess,
  [actions.FETCH_STUDENT_DIARY_FORM_ERROR]: fetchStudentDiaryError,
  [actions.FETCH_DIARY_REPORT_INITIAL_RECIPIENTS]:
    fetchDiaryReportInitialRecipientsRequest,
  [actions.FETCH_DIARY_REPORT_INITIAL_RECIPIENTS_SUCCESS]:
    fetchDiaryReportInitialRecipientsSuccess,
  [actions.FETCH_DIARY_REPORT_STUDENTS_SUCCESS]: fetchDiaryReportStudentSuccess,
  [actions.FETCH_DIARY_REPORT_SECTIONS]: fetchDiaryReportSectionsRequest,
  [actions.FETCH_DIARY_REPORT_SECTIONS_SUCCESS]:
    fetchDiaryReportSectionsSuccess,
  [actions.FETCH_STUDENTS_DIARIES_FORM]: fetchStudentsDiariesForm,
  [actions.FETCH_STUDENTS_DIARIES_FORM_SUCCESS]:
    fetchStudentsDiariesFormSuccess,
  [actions.FETCH_STUDENTS_DIARIES_FORM_ERROR]: fetchStudentsDiariesFormError,

  [actions.ERROR]: handleError,
};

const dailySummaries = createModuleReducer(INITIAL_STATE, HANDLERS);

export default dailySummaries;
