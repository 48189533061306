import React from 'react';

import { RecipientCard } from 'components/RecipientSelectList/RecipientCard';

import { Props } from './types';

export const FamilyListComponent: React.FC<Props> = ({
  classroom,
  selectedRecipients,
  onSelect,
  onRemove,
}) => {
  return (
    <>
      {classroom.students.map((student, index) => (
        <RecipientCard
          key={index}
          recipient={student}
          isSelected={
            !!selectedRecipients?.familyTab[classroom.id]?.students?.[
              student.id
            ]
          }
          toggleCheckbox={(checked) =>
            checked ? onRemove(student) : onSelect(student)
          }
        />
      ))}
    </>
  );
};

export const FamilyList = React.memo(
  FamilyListComponent,
  (prev, next) =>
    prev.selectedRecipients.familyTab[prev.classroom.id] ===
    next.selectedRecipients.familyTab[prev.classroom.id]
);
