import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Box,
  CardList,
  DefaultThemeProps,
  Grid,
  RecipientTag,
  SubtleIconButton,
  Tag,
  theme,
} from '@agendaedu/ae-web-components';

import { handoutsGrid } from 'core/constants/handouts';
import { HANDOUTS_IMAGES } from 'core/constants';
import withAppContext from 'core/hoc/withAppContext';

import actionHandouts from 'store/handouts/actions';
import { ActiveTermsFilters, FilterStatesProps } from 'store/filters/types';
import {
  HandoutProps,
  HandoutState,
  FetchHandoutsRequestParams,
} from 'store/handouts/types';

import EmptyState from 'components/EmptyState';
import HandoutsFilters from 'components/Handouts/Filters/HandoutsFilters';
import NewHandoutModal from 'components/Handouts/NewHandoutModal';

import { HandoutsListSkeleton } from './skeleton';

import * as S from './styles';

import { HandoutScreenProps, StatusType } from './types';

const HandoutScreen = ({ appContext: { dataArea } }: HandoutScreenProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts', 'common']);

  const initialPage = 1;

  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  const {
    handouts,
    pagination: { page: currentPage, totalPages },
    isLoading,
    isInitialLoading,
    isMoreHandoutsLoading,
    filters,
  } = useSelector((state: HandoutState) => state.handouts);

  const { activeSchoolTerms } = useSelector(
    (state: FilterStatesProps) => state.filters
  );

  const { fetchHandoutsRequest, fetchMoreHandoutsRequest } = actionHandouts;

  const recipientTag = (handout: HandoutProps) => ({
    type: t(`card_columns.tooltip_${handout.attributes.createdFor}_title_text`),
    recipients: handout.relationships[handout.attributes.createdFor].data.map(
      ({ attributes: { name } }) => ({ name })
    ),
  });

  const hasMoreHandouts = currentPage < totalPages;

  const fetchHandouts = useCallback(
    (parameters?: FetchHandoutsRequestParams) => {
      const params = {
        ...parameters,
        page: initialPage,
      };

      dispatch(fetchHandoutsRequest(params));
    },
    [dispatch, fetchHandoutsRequest]
  );

  const fetchMoreHandouts = useCallback(() => {
    dispatch(fetchMoreHandoutsRequest({ ...filters, page: currentPage + 1 }));
  }, [dispatch, fetchMoreHandoutsRequest, currentPage, filters]);

  const renderActionsItems = (handout: HandoutProps) => {
    const {
      id,
      attributes: { userPermissions },
    } = handout;

    const actions = [
      {
        as: 'a',
        title: t('actions.open_button_text'),
        link: `/${dataArea}/handouts/${id}`,
        target: '_blank',
      },
      {
        as: 'a',
        title: t('actions.show_button_text'),
        link: `/${dataArea}/handouts/${id}`,
      },
    ];

    userPermissions.edit &&
      actions.push({
        as: 'a',
        title: t('actions.edit_button_text'),
        link: `/${dataArea}/handouts/${id}/edit`,
      });

    userPermissions.create &&
      actions.push({
        as: 'a',
        title: t('actions.duplicate_button_text'),
        link: `/${dataArea}/handouts/${id}/duplicate`,
      });

    return actions;
  };

  const renderStatusType: StatusType<
    | 'pending'
    | 'pending_schedule'
    | 'sent'
    | 'scheduled'
    | 'rejected'
    | 'deleted'
  > = {
    pending: {
      name: t('tags.pending'),
      variant: 'warning',
    },
    pending_schedule: {
      name: t('tags.pending'),
      variant: 'warning',
    },
    sent: {
      name: t('tags.sent'),
      variant: 'positive',
    },
    scheduled: {
      name: t('tags.scheduled'),
      variant: 'informative',
    },
    rejected: {
      name: t('tags.rejected'),
      variant: 'negative',
    },
    deleted: {
      name: t('tags.deleted'),
      variant: 'neutral',
    },
  };

  const cardGridColumns = (handout: HandoutProps) => {
    const {
      relationships: { category, classrooms },
    } = handout;

    const validateCategory = (minQuantity: number) =>
      category?.data?.attributes.name.length >= minQuantity;

    const validateClassroom = (minQuantity: number) =>
      classrooms?.data.length === 1 &&
      classrooms?.data[0]?.attributes.name.length >= minQuantity;

    return {
      categoryAndLogsGridColumn: {
        mobileXS: validateCategory(30) ? 'span 10' : 'span 5',
        tabletMD: validateCategory(32) ? 'span 10' : 'span 5',
        desktopLG: 'span 2',
      },

      classroomGridColumn: {
        mobileXS: validateClassroom(30) ? 'span 10' : 'span 5',
        tabletMD: validateClassroom(32) ? 'span 10' : 'span 5',
        desktopLG: 'span 2',
      },

      statusGridColumn: {
        mobileXS: validateClassroom(30) ? 'span 10' : 'span 5',
        tabletMD: validateClassroom(32) ? 'span 10' : 'span 5',
        desktopLG: 'span 1',
      },
    };
  };

  useEffect(() => {
    if (!activeSchoolTerms.length || handouts.length) return;

    const currentActiveSchoolTermId = activeSchoolTerms.find(
      (terms: ActiveTermsFilters) => terms.isActive === true
    ).value;
    fetchHandouts({ activeSchoolTermId: currentActiveSchoolTermId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSchoolTerms, fetchHandouts]);

  return (
    <S.HandoutScreenWrapper>
      <HandoutsFilters handleSubmitFilters={fetchHandouts} />

      {isLoading || isInitialLoading ? (
        <HandoutsListSkeleton />
      ) : (
        <Grid item gutter="md">
          <Box display="flex" justifyContent="flex-end">
            <S.AmountHandouts>
              {t('amount_label_text', { count: handouts.length })}
            </S.AmountHandouts>
          </Box>
          {handouts.length > 0 ? (
            <CardList
              loadMore={!isMoreHandoutsLoading && fetchMoreHandouts}
              hasMore={hasMoreHandouts}
              loader={
                <S.SkeletonListItem
                  width="100%"
                  height={96}
                  primaryColor={neutral.gray6}
                  borderRadius={radius.md}
                  isFullWidth
                />
              }
              initialLoad={true}
              threshold={200}
              pageStart={1}
              marginBottom="md"
            >
              {handouts.map((handout: HandoutProps) => (
                <Box
                  key={`handout-${handout.id}`}
                  width="100%"
                  display="flex"
                  alignItems={{
                    _: 'flex-start',
                    desktopLG: 'center',
                  }}
                >
                  <S.CardAnchorReact to={`/${dataArea}/handouts/${handout.id}`}>
                    <Grid
                      gutter={{ _: 'md', tabletMD: 'xs2' }}
                      gridTemplateColumns={handoutsGrid.cardTemplateColumns}
                    >
                      <Grid item gridColumn={handoutsGrid.titleColumn}>
                        <Box
                          mr={handoutsGrid.columnsMargin}
                          display="flex"
                          flexDirection="column"
                        >
                          <S.Title>
                            {handout.attributes.status === 'rejected'
                              ? t('card_columns.title_rejected_text')
                              : handout.attributes.sendAt
                              ? moment(
                                  handout.attributes.sendAt,
                                  'YYYY-MM-DD'
                                ).format(
                                  t('card_columns.title_sent_in_from_text')
                                )
                              : t('card_columns.title_to_send_text')}
                          </S.Title>
                          <S.Content title={handout.attributes.title}>
                            <S.HandoutName>
                              {handout.attributes.title}
                            </S.HandoutName>
                          </S.Content>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        gridColumn={
                          cardGridColumns(handout).categoryAndLogsGridColumn
                        }
                      >
                        <Box
                          mr={handoutsGrid.columnsMargin}
                          display="flex"
                          flexDirection="column"
                        >
                          <S.Title>
                            {t('card_columns.category_title_text')}
                          </S.Title>

                          <S.Content>
                            <span>
                              {handout.relationships.category?.data
                                ? handout.relationships.category?.data
                                    .attributes.name
                                : '-'}
                            </span>
                          </S.Content>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        gridColumn={
                          cardGridColumns(handout).categoryAndLogsGridColumn
                        }
                      >
                        <Box
                          mr={handoutsGrid.columnsMargin}
                          display="flex"
                          flexDirection="column"
                        >
                          <S.Title>{t('card_columns.view_title_text')}</S.Title>
                          <S.Content>
                            <span>
                              {t('card_columns.count_view_text', {
                                view: handout.relationships.count.views,
                                total: handout.relationships.count.total,
                              })}
                            </span>
                          </S.Content>
                        </Box>
                      </Grid>

                      {!!handout.relationships.classrooms.data.length && (
                        <Grid
                          item
                          gridColumn={
                            cardGridColumns(handout).classroomGridColumn
                          }
                        >
                          <Box
                            height="100%"
                            mr={handoutsGrid.columnsMargin}
                            display="flex"
                            alignItems="center"
                          >
                            <Box display="flex" maxWidth="70%">
                              <RecipientTag
                                enableEllipsis
                                variant="neutral"
                                modalTitle={recipientTag(handout).type}
                                recipientType={recipientTag(handout).type}
                                recipients={recipientTag(handout).recipients}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      )}

                      <Grid
                        item
                        gridColumn={cardGridColumns(handout).statusGridColumn}
                      >
                        <Box
                          height="100%"
                          mr={handoutsGrid.columnsMargin}
                          display="flex"
                          alignItems="center"
                        >
                          <Tag
                            name={
                              handout.attributes.updatedAt &&
                              handout.attributes.status !== 'deleted'
                                ? t('card_columns.updated_status_text')
                                : renderStatusType[handout.attributes.status]
                                    .name
                            }
                            variant={
                              handout.attributes.updatedAt &&
                              handout.attributes.status !== 'deleted'
                                ? 'informative'
                                : renderStatusType[handout.attributes.status]
                                    .variant
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </S.CardAnchorReact>

                  <Grid
                    item
                    gridColumn={{
                      desktopLG: 'span 1',
                    }}
                  >
                    <Box
                      position="absolute"
                      top={handoutsGrid.subtleIconSpace.top}
                      right={handoutsGrid.subtleIconSpace.right}
                    >
                      <SubtleIconButton
                        actions={renderActionsItems(handout)}
                        align="right"
                      />
                    </Box>
                  </Grid>
                </Box>
              ))}
            </CardList>
          ) : (
            <S.EmptyStateWrapper>
              <EmptyState
                message={t('empty_state_text')}
                imgUrl={HANDOUTS_IMAGES.no_handouts}
              />
            </S.EmptyStateWrapper>
          )}
        </Grid>
      )}

      <NewHandoutModal />
    </S.HandoutScreenWrapper>
  );
};

export default withAppContext(HandoutScreen);
