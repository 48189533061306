import React from 'react';

import { flags } from 'core/constants/flags';
import {
  DISPLAY_STATUS_PAID,
  CAN_GENERATE_BILLET_OR_PIX_BY_SCHOOL_PRODUCT_STATUS,
} from 'core/constants/index';

import AgendaIcon from 'components/AgendaIcon';
import Avatar from 'components/Avatar';
import ClassroomsList from 'components/ClassroomsList';
import ColoredText from 'components/ColoredText';
import DropdownButton from 'components/DropdownButton';
import OutlineBox from 'components/OutlineBox';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

import * as S from './styles';

const formattedVisualization = ({ visualizations, attributes }) => {
  const filteredVisualizations = visualizations.filter(
    (visualization) => visualization.attributes.responsible_exist
  );

  const views = filteredVisualizations.length ? (
    <div>
      <ColoredText variation="bold">Visualizou pelo aplicativo</ColoredText>
      <br />
      {filteredVisualizations.map((view, index) => (
        <div key={`${view.attributes.name}${index}`}>
          <span>
            {view.attributes.name} {view.attributes.created_at}
          </span>
          {index + 1 != filteredVisualizations.length && <hr />}
        </div>
      ))}
    </div>
  ) : null;

  const separator = filteredVisualizations.length ? <hr /> : null;

  const not_seen = attributes.responsibles_not_seen.length ? (
    <div>
      {separator}
      <ColoredText variation="bold">Não Visualizou</ColoredText>
      <br />
      {attributes.responsibles_not_seen.map((name, index) => (
        <div key={`${name}${index}`}>
          <span>{name}</span>
          <br />
        </div>
      ))}
    </div>
  ) : null;

  return [views, not_seen];
};

const tooltipMessages = (attributes) => {
  const mappings = {
    manually_paid: `Pago na escola por ${attributes.by_responsible} ${attributes.paid_at}`,
    credit_card_paid: `Pago no aplicativo por ${attributes.by_responsible} ${attributes.paid_at}`,
    billet_paid: `Pago no boleto ${attributes.paid_at}`,
    pix_paid: `Pago via PIX ${attributes.paid_at}`,
    refunded: `Reembolsado ${attributes.updated_at}`,
    rejected_by_responsible: `Rejeitado por ${attributes.by_responsible} ${attributes.updated_at}`,
    to_refund:
      'Não há reembolso automático para pagamentos via boleto. Você precisa realizar o reembolso dos responsáveis',
  };

  const content =
    typeof mappings[attributes.status] === 'function'
      ? mappings[attributes.status]()
      : mappings[attributes.status];

  return <span>{content}</span>;
};

export default [
  {
    Header: 'Aluno',
    accessor: ({ order: { attributes } }) => (
      <span className="avatar-with-name">
        <Avatar user={attributes.student_profile.data} />
        <div>
          <b>{attributes.student_profile.data.attributes.name}</b>
          <ClassroomsList
            classroomsNames={
              attributes.student_profile.data.attributes.classroom_names
            }
          />
        </div>
      </span>
    ),
  },
  {
    Header: 'Responsável',
    accessor: ({ order: { attributes } }) =>
      attributes.responsibles_names.join(', '),
  },
  {
    Header: 'Pagamento',
    accessor: ({ order: { attributes } }) => {
      if (DISPLAY_STATUS_PAID.includes(attributes.status)) {
        const last_payment = attributes.last_payment.data;

        return last_payment.attributes.installments > 1
          ? `Parcelado em ${last_payment.attributes.installments}x`
          : 'À vista';
      }

      return <AgendaIcon name="dash" />;
    },
  },
  {
    Header: 'Visualização',
    accessor: ({ order: { visualizations, attributes } }) =>
      visualizations.length ? (
        <Tooltip
          content={formattedVisualization({ visualizations, attributes })}
          position={TOP_CENTER}
          className="orderVisualizations"
        >
          <AgendaIcon name="eye" />
        </Tooltip>
      ) : (
        <AgendaIcon name="dash" />
      ),
  },
  {
    Header: 'Situação',
    accessor: ({ order: { attributes } }) => {
      const mappings = {
        waiting_payment: {
          text: 'Em aberto',
          variation: 'warning',
        },
        processing: {
          text: 'Processando',
          variation: 'info',
        },
        authorized: {
          text: 'Autorizado',
          variation: 'info',
        },
        credit_card_paid: {
          text: 'Pago via cartão',
          variation: 'success',
        },
        pix_paid: {
          text: 'Pago via PIX',
          variation: 'success',
        },
        expired: {
          text: 'Encerrado',
        },
        canceled: {
          text: 'Cancelado',
          variation: 'dimming',
        },
        processing_refund: {
          text: 'Processando reembolso',
          variation: 'dark',
        },
        refunded: {
          text: 'Reembolsado',
          variation: 'dark',
        },
        refused: {
          text: 'Recusado',
          variation: 'dimming',
        },
        manual_processing: {
          text: 'Pagar na escola',
          variation: 'warning',
        },
        rejected_by_responsible: {
          text: 'Rejeitado',
          variation: 'dimming',
        },
        manually_paid: {
          text: 'Pago na escola',
          variation: 'success',
        },
        generated_billet: {
          text: 'Gerou boleto',
          variation: 'info',
        },
        generated_pix: {
          text: 'Gerou PIX',
          variation: 'info',
        },
        billet_paid: {
          text: 'Pago via Boleto',
          variation: 'success',
        },
        to_refund: {
          text: 'À reembolsar',
          variation: 'warning',
        },
        paid_divergent: {
          text: 'Valor divergente',
          variation: 'warning',
        },
        paid: {
          text: 'Pago',
          variation: 'success',
        },
      };

      const status = attributes.status;

      return [
        'paid',
        'refunded',
        'rejected_by_responsible',
        'manually_paid',
        'credit_card_paid',
        'billet_paid',
        'to_refund',
        'pix_paid',
      ].includes(status) ? (
        <Tooltip
          content={<div>{tooltipMessages(attributes)}</div>}
          position={TOP_CENTER}
        >
          <OutlineBox variation={mappings[status].variation}>
            {mappings[status].text}
          </OutlineBox>
        </Tooltip>
      ) : (
        <OutlineBox variation={mappings[status].variation}>
          {mappings[status].text}
        </OutlineBox>
      );
    },
  },
  {
    Header: 'Ação',
    accessor: ({
      order: { attributes, actions },
      policies,
      showActionButtons,
      schoolProduct,
    }) => {
      const schoolProductLDFLag = policies.edupay_active_flags.includes(
        flags.SCHOOL_PRODUCT_CANCELLATION
      );

      const checkoutWebV1LDFlag = policies.edupay_active_flags.includes(
        flags.CHECKOUT_WEB_V1
      );

      const pixIssuedAtSchoolProductAndNegotiationLDFlag =
        policies.core_banking_flags.includes(
          flags.PIX_ISSUED_AT_SCHOOL_PRODUCT_AND_NEGOTIATION
        );

      const checkStatus = (statuses) => statuses.includes(attributes.status);

      const actionPaidAtAtSchool = {
        onClick: actions
          ? policies.edupay_active_flags.includes(
              flags.SCHOOL_PRODUCT_PAID_AT_SCHOOL
            )
            ? actions.paidAtSchool
            : actions.manuallyModal
          : null,
        text: 'Pago na escola',
      };

      const mappings = {
        paid: {
          actions: [],
        },
        waiting_payment: {
          actions: [],
        },
        processing: {
          actions: [],
        },
        authorized: {
          actions: [],
        },
        refunded: {
          actions: [],
        },
        expired: {
          actions: [],
        },
        canceled: {
          actions: [],
        },
        processing_refund: {
          actions: [],
        },
        refused: {
          actions: [],
        },
        manual_processing: {
          actions: [],
        },
        rejected_by_responsible: {
          actions: [],
        },
        manually_paid: {
          actions: [],
        },
        generated_billet: {
          actions: [
            {
              onClick: actions ? actions.generateBilletModal : null,
              text: 'Gerar boleto',
            },
            {
              onClick: actions ? actions.billetLinkModal : null,
              text: 'Link do boleto',
            },
          ],
        },
        credit_card_paid: {
          actions: [],
        },
        billet_paid: {
          actions: [],
        },
        paid_divergent: {
          actions: [],
        },
        to_refund: {
          actions: [],
        },
        pix_paid: {
          actions: [],
        },
        generated_pix: {
          actions: [],
        },
      };

      if (attributes.can_refund) {
        mappings.credit_card_paid.actions.push({
          onClick: actions ? actions.refundModal : null,
          text: 'Reembolsar',
        });

        mappings.pix_paid.actions.push({
          onClick: actions ? actions.refundModal : null,
          text: 'Reembolsar',
        });

        mappings.manually_paid.actions.push({
          onClick: actions ? actions.refundOrder : null,
          text: 'Reembolsar',
        });
      }

      if (policies.can_mark_manually_paid_order) {
        mappings.waiting_payment.actions.push(actionPaidAtAtSchool);

        mappings.refused.actions.push(actionPaidAtAtSchool);

        mappings.generated_billet.actions.push(actionPaidAtAtSchool);

        mappings.generated_pix.actions.push(actionPaidAtAtSchool);

        mappings.expired.actions.push(actionPaidAtAtSchool);

        if (
          schoolProduct &&
          schoolProduct.attributes.allowed_payment_method.includes('billet')
        ) {
          mappings.waiting_payment.actions.push({
            onClick: actions ? actions.generateBilletModal : null,
            text: 'Gerar boleto',
          });

          mappings.generated_pix.actions.push({
            onClick: actions ? actions.generateBilletModal : null,
            text: 'Gerar boleto',
          });

          mappings.refused.actions.push({
            onClick: actions ? actions.generateBilletModal : null,
            text: 'Gerar boleto',
          });
        }

        if (!schoolProduct.attributes?.source_api_v2) {
          if (policies.can_cancel_school_product) {
            mappings.waiting_payment.actions.push({
              onClick: actions
                ? schoolProductLDFLag
                  ? actions.cancelModalLD
                  : actions.cancelModal
                : null,
              text: 'Cancelar cobrança',
            });

            mappings.generated_billet.actions.push({
              onClick: actions
                ? schoolProductLDFLag
                  ? actions.cancelModalLD
                  : actions.cancelModal
                : null,
              text: 'Cancelar cobrança',
            });

            mappings.generated_pix.actions.push({
              onClick: actions
                ? schoolProductLDFLag
                  ? actions.cancelModalLD
                  : actions.cancelModal
                : null,
              text: 'Cancelar cobrança',
            });
          }
        }
      }

      if (
        schoolProduct &&
        schoolProduct.attributes.allowed_payment_method.includes('pix') &&
        pixIssuedAtSchoolProductAndNegotiationLDFlag &&
        checkStatus(CAN_GENERATE_BILLET_OR_PIX_BY_SCHOOL_PRODUCT_STATUS)
      ) {
        mappings.waiting_payment.actions.push({
          text: 'Gerar PIX',
          onClick: actions ? actions.generatePixModal : null,
        });

        mappings.generated_billet.actions.push({
          text: 'Gerar PIX',
          onClick: actions ? actions.generatePixModal : null,
        });

        mappings.generated_pix.actions.push({
          text: 'Gerar PIX',
          onClick: actions ? actions.generatePixModal : null,
        });

        mappings.refused.actions.push({
          text: 'Gerar PIX',
          onClick: actions ? actions.generatePixModal : null,
        });
      }

      if (
        schoolProduct &&
        schoolProduct.attributes.allowed_payment_method.includes('pix') &&
        pixIssuedAtSchoolProductAndNegotiationLDFlag &&
        checkStatus('generated_pix')
      ) {
        mappings.waiting_payment.actions.push({
          text: 'Ver chave PIX',
          onClick: actions ? actions.pixLinkModal : null,
        });

        mappings.generated_billet.actions.push({
          text: 'Ver chave PIX',
          onClick: actions ? actions.pixLinkModal : null,
        });

        mappings.generated_pix.actions.push({
          text: 'Ver chave PIX',
          onClick: actions ? actions.pixLinkModal : null,
        });

        mappings.refused.actions.push({
          text: 'Ver chave PIX',
          onClick: actions ? actions.pixLinkModal : null,
        });
      }

      if (
        schoolProduct &&
        checkStatus(CAN_GENERATE_BILLET_OR_PIX_BY_SCHOOL_PRODUCT_STATUS) &&
        checkoutWebV1LDFlag
      ) {
        mappings.waiting_payment.actions.push({
          text: 'Gerar link de pagamento',
          onClick: actions ? actions.generateLinkModal : null,
        });

        mappings.generated_billet.actions.push({
          text: 'Gerar link de pagamento',
          onClick: actions ? actions.generateLinkModal : null,
        });

        mappings.generated_pix.actions.push({
          text: 'Gerar link de pagamento',
          onClick: actions ? actions.generateLinkModal : null,
        });

        mappings.refused.actions.push({
          text: 'Gerar link de pagamento',
          onClick: actions ? actions.generateLinkModal : null,
        });
      }

      const actionsMappings = showActionButtons
        ? mappings[attributes.status].actions
        : [];

      return (
        <S.DropdownButtonContainer>
          <DropdownButton
            dropdownItems={actionsMappings}
            text="Ações"
            variation="secondary"
          />
        </S.DropdownButtonContainer>
      );
    },
  },
];
