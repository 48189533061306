import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';

import { dropdownDirection } from 'core/utils/table';
import withAppContext from 'core/hoc/withAppContext';
import { flags } from 'core/constants/flags';
import {
  CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS,
  CAN_MANUALLY_PAID_BY_RECURRENT_STATUS,
  CAN_DELAY_RECURRENT_BILL_BY_STATUS,
} from 'core/constants/index';
import { canUseGuaranteedMonthlyFee } from 'core/utils/guaranteedMonthlyFee';
import { canGenerateBilletOrPix } from 'core/utils/canGenerateBilletOrPix';
import { canEditRecurrentBill } from 'core/utils/recurrentBill';

import DropdownButton from 'components/DropdownButton';
import Currency from 'components/Currency';
import FormCheckbox from 'components/Form/Checkbox';
import OutlineBox from 'components/OutlineBox';
import RecurrentClickShowModal from 'components/Payments/Recurrent/RecurrentClickShowModal';
import UserDisplay from 'components/UserDisplay';

import recurrentStatus from '../RecurrentStatus';
import { useTranslation } from 'react-i18next';

const SelectAllRecurrent = () => {
  const dispatch = useDispatch();
  const { toggleSelectAllRecurrent, setSelectAllRecurrentRequest } =
    actionRecurrentPlans;

  const { recurrentPlans, wasSelectedAllRecurrent, filters } = useSelector(
    (state) => state.recurrentPlans
  );

  const handleSelectRecurrentElegibleToEdit = useCallback(() => {
    const params = { ...filters, elegible_to_edit: true };

    if (!wasSelectedAllRecurrent)
      dispatch(setSelectAllRecurrentRequest(params));
  }, [
    dispatch,
    setSelectAllRecurrentRequest,
    wasSelectedAllRecurrent,
    filters,
  ]);

  const handleToggleSelectAllRecurrent = useCallback(() => {
    dispatch(toggleSelectAllRecurrent());
  }, [dispatch, toggleSelectAllRecurrent]);

  const handleCheckSelectAll = () => {
    handleToggleSelectAllRecurrent();
    handleSelectRecurrentElegibleToEdit();
  };

  const handleDisableSelectAll = useCallback(() => {
    let disabled = true;

    recurrentPlans?.map((recurrent) => {
      if (
        canEditRecurrentBill(
          recurrent.attributes.status,
          recurrent.attributes.is_wallet_fidc,
          recurrent.attributes.is_wallet_guaranteed_monthly_fee
        )
      ) {
        disabled = false;
        return;
      }
    });

    return disabled;
  }, [recurrentPlans]);

  return (
    <FormCheckbox
      checked={wasSelectedAllRecurrent}
      onChange={handleCheckSelectAll}
      disabled={handleDisableSelectAll()}
    />
  );
};

const SelectRecurrent = ({ id, attributes }) => {
  const dispatch = useDispatch();
  const { setSelectRecurrent } = actionRecurrentPlans;
  const { selectedRecurrent } = useSelector((state) => state.recurrentPlans);

  const handleSelect = useCallback(() => {
    dispatch(setSelectRecurrent(id));
  }, [dispatch, id, setSelectRecurrent]);

  return (
    <FormCheckbox
      checked={selectedRecurrent?.includes(id)}
      onChange={handleSelect}
      disabled={
        !canEditRecurrentBill(
          attributes.status,
          attributes.is_wallet_fidc,
          attributes.is_wallet_guaranteed_monthly_fee,
          true
        )
      }
    />
  );
};

SelectRecurrent.propTypes = {
  id: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    is_wallet_fidc: PropTypes.bool,
    is_wallet_guaranteed_monthly_fee: PropTypes.bool,
    status: PropTypes.string,
  }).isRequired,
};

const StudentProfile = ({ attributes, actions }) => (
  <RecurrentClickShowModal actions={actions}>
    <UserDisplay
      user={attributes.student_profile.data}
      classroomsNames={
        attributes.student_profile.data.attributes.classroom_names
      }
      size="medium"
    />
  </RecurrentClickShowModal>
);

StudentProfile.propTypes = {
  attributes: PropTypes.shape({
    student_profile: PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          classroom_names: PropTypes.array,
        }),
      }),
    }),
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Title = ({ attributes, actions }) => (
  <RecurrentClickShowModal actions={actions}>
    {attributes.title}
  </RecurrentClickShowModal>
);

Title.propTypes = {
  attributes: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const RecipientWalletName = ({ attributes, actions }) => (
  <RecurrentClickShowModal actions={actions}>
    {attributes.recipient_wallet_name}
  </RecurrentClickShowModal>
);

RecipientWalletName.propTypes = {
  attributes: PropTypes.shape({
    title: PropTypes.string,
    recipient_wallet_name: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Installments = ({ attributes, actions }) => (
  <RecurrentClickShowModal actions={actions}>
    <div className="tagInstallments">
      {`${attributes.bill_number} de ${attributes.bills_quantity}`}
    </div>
  </RecurrentClickShowModal>
);

Installments.propTypes = {
  attributes: PropTypes.shape({
    bill_number: PropTypes.number,
    bills_quantity: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Expire = ({ attributes, actions }) => (
  <RecurrentClickShowModal actions={actions}>
    {attributes.due_date}
  </RecurrentClickShowModal>
);

Expire.propTypes = {
  attributes: PropTypes.shape({
    due_date: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Price = ({ attributes, actions }) => (
  <RecurrentClickShowModal actions={actions}>
    <Currency value={attributes.price_cents} />
  </RecurrentClickShowModal>
);

Price.propTypes = {
  attributes: PropTypes.shape({
    price_cents: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Situation = ({ attributes, actions }) => (
  <RecurrentClickShowModal actions={actions}>
    <OutlineBox variation={recurrentStatus[attributes.status].variation}>
      {recurrentStatus[attributes.status].text}
    </OutlineBox>
  </RecurrentClickShowModal>
);

Situation.propTypes = {
  attributes: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const MenuOptions = (id, attributes, actions, checkStatus) => {
  const { t } = useTranslation(['recurrent_plan']);
  const {
    can_delay: canDelay,
    is_wallet_fidc: isWalletFidc,
    is_wallet_guaranteed_monthly_fee: isGuaranteedFee,
    status,
    kind,
  } = attributes;

  const isRegular = kind === 'regular';

  return [
    canEditRecurrentBill(status, isWalletFidc, isGuaranteedFee) &&
      isRegular && {
        text: t('list.actions.edit_bill'),
        path: `/schools/recurrent/recurrent_plans/${attributes.recurrent_plan_id}/recurrent_bills/${id}/edit`,
        target: '_self',
      },
    checkStatus(CAN_MANUALLY_PAID_BY_RECURRENT_STATUS) && {
      text: t('list.actions.manual_deal'),
      onClick: () => actions.manualDealModal(),
    },
    checkStatus(CAN_DELAY_RECURRENT_BILL_BY_STATUS) &&
      isRegular &&
      canDelay && {
        text: t('list.actions.delay_bill'),
        onClick: () => actions.delay(),
      },
  ];
};

const Actions = ({ id, attributes, actions }, row, rowCount) => {
  const { t } = useTranslation(['recurrent_plan', 'common']);
  const { hasBatchSelection } = useSelector((state) => state.recurrentPlans);
  const {
    currentUser: schoolUser,
    policies: {
      core_banking_flags: coreBankingFlags,
      edupay_active_flags: edupayActiveFlags,
    },
  } = useSelector((state) => state.root);
  const {
    attributes: { is_debug_user: isDebugUser },
  } = schoolUser;

  const checkStatus = (statuses) => statuses.includes(attributes.status);

  let options = [
    {
      text: t('list.actions.view_plan'),
      path: `/schools/recurrent/recurrent_plans/${attributes.recurrent_plan_id}`,
      target: '_self',
    },
    canGenerateBilletOrPix(
      isDebugUser,
      attributes.is_wallet_guaranteed_monthly_fee,
      attributes.status
    ) &&
      attributes.allowed_payment_method.includes('billet') &&
      checkStatus(CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS) && {
        text: t('list.actions.generate_billet'),
        onClick: () => actions.billetModal(),
      },
    attributes.allowed_payment_method.includes('billet') &&
      checkStatus(['generated_billet']) && {
        text: t('list.actions.billet_link'),
        onClick: () => actions.billetLinkModal(),
      },
    attributes.allowed_payment_method.includes('pix') &&
      coreBankingFlags.includes(flags.PIX_ISSUED_AT_SCHOOL) &&
      checkStatus(CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS) && {
        text: t('list.actions.generate_pix'),
        onClick: () => actions.pixModal(),
      },
    attributes.allowed_payment_method.includes('pix') &&
      coreBankingFlags.includes(flags.PIX_ISSUED_AT_SCHOOL) &&
      checkStatus('generated_pix') && {
        text: t('list.actions.pix_key'),
        onClick: () => actions.pixLinkModal(),
      },
    edupayActiveFlags.includes(flags.CHECKOUT_WEB_V1) &&
      checkStatus(CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS) && {
        text: t('list.actions.generate_link'),
        onClick: () => actions.linkModal(),
      },
  ];

  if (!attributes.is_wallet_guaranteed_monthly_fee) {
    options.push(...MenuOptions(id, attributes, actions, checkStatus));
  } else if (
    canUseGuaranteedMonthlyFee(
      isDebugUser,
      attributes.is_wallet_guaranteed_monthly_fee
    )
  ) {
    options.push(...MenuOptions(id, attributes, actions, checkStatus));
  }

  options = options.filter((option) => option !== false);

  return (
    <DropdownButton
      dropdownItems={options}
      text={t('common:button.actions')}
      variation="secondary"
      disabled={hasBatchSelection}
      direction={dropdownDirection({
        options: options.length,
        optionsPerRow: 3,
        row,
        rowCount,
      })}
    />
  );
};

Actions.propTypes = {
  id: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    status: PropTypes.string,
    recurrent_plan_id: PropTypes.number,
    can_delay: PropTypes.bool,
    allowed_payment_method: PropTypes.arrayOf(
      PropTypes.oneOf(['billet', 'credit_card', 'pix'])
    ),
    is_wallet_guaranteed_monthly_fee: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    manuallyPay: PropTypes.func,
    billetModal: PropTypes.func,
    billetLinkModal: PropTypes.func,
    pixModal: PropTypes.func,
    pixLinkModal: PropTypes.func,
    linkModal: PropTypes.func,
    delay: PropTypes.func,
    negotiation: PropTypes.func,
  }).isRequired,
};

const RecurrentTabs = [
  {
    Header: SelectAllRecurrent,
    accessor: SelectRecurrent,
  },
  {
    Header: 'Aluno',
    accessor: StudentProfile,
  },
  {
    Header: 'Título',
    accessor: Title,
  },
  {
    Header: 'Carteira destino',
    accessor: RecipientWalletName,
  },
  {
    Header: 'Parcela',
    accessor: Installments,
  },
  {
    Header: 'Valor',
    accessor: Price,
  },
  {
    Header: 'Vencimento',
    accessor: Expire,
  },
  {
    Header: 'Situação',
    accessor: Situation,
  },
  {
    Header: 'Ações',
    accessor: withAppContext(Actions),
  },
];

export default RecurrentTabs;
