export const PERMISSIONS_PAYMENTS = {
  config_wallet: 'Configurar carteira',
  display_balance:
    'Visualizar somatório de valores das cobranças no módulo de cobranças recorrentes',
  transfer_balance: 'Transferir saldo disponível',
  create_school_product: 'Criar, editar e excluir antes da aprovação',
  approve: 'Aprovar envio',
  disapprove: 'Reprovar envio',
  edit_after_approved: 'Editar após aprovado',
  refund_payments: 'Reembolsar pagamentos',
  mark_manually_paid: 'Marcar pagamentos como "Pago na escola"',
  cancel_school_product: 'Cancelar pagamentos',
  delete_after_cancellation: 'Excluir após cancelamento',
  negotiation: 'Realizar negociação',
};

export const PERMISSIONS_HANDOUT_TEMPLATES = {
  edit_template: 'Editar modelo',
  show_template: 'Exibir modelo',
  create_template: 'Criar modelo',
  delete_template: 'Excluir modelo',
};

export const PERMISSIONS_DAILY_SUMMARIES = {
  upsert: 'Preencher',
  generate_report: 'Extrair relatório',
  toggle_sections: 'Habilitar/Desabilitar seções',
  create_sections: 'Criar seções',
  show_sections: 'Visualizar seções',
  edit_sections: 'Editar seções',
  delete_sections: 'Excluir seções',
}

export const ROLE_LIST = {
  master: 'Master',
  manager: 'Diretor',
  coordinator: 'Coordenador',
  secretariat: 'Secretário',
  teacher: 'Professor',
  assistant: 'Auxiliar',
  financial: 'Financeiro',
  financial_assistant: 'Auxiliar Financeiro',
};

export const PERMISSIONS_ACTIONS = {
  edit: 'Editar',
  show: 'Exibir',
  create: 'Criar',
  delete: 'Excluir',
  resend: 'Reenviar',
  approve: 'Aprovar',
  disapprove: 'Reprovar',
  schedule: 'Agendar',
  mass_resend: 'Reenvio em massa',
  cancel: 'Cancelar',
  add_photo: 'Adicionar foto',
  // precisam ser ajustados para o padrão, futuramente deve ser ajustados
  survey_show: 'Exibir',
  survey_create: 'Criar',
  survey_edit: 'Editar',
  survey_approve: 'Aprovar',
  survey_disapprove: 'Reprovar',
  survey_resend: 'Reenviar',
  survey_delete: 'Excluir',
  ...PERMISSIONS_HANDOUT_TEMPLATES,
  ...PERMISSIONS_PAYMENTS,
  ...PERMISSIONS_DAILY_SUMMARIES,
};

export const PERMISSIONS_ROLES = {
  classrooms: 'Turmas',
  hubstore: 'Hubstore',
  panel: 'Painel',
  contracts: 'Constratos',
  payments: 'Pagamentos',
  handouts: 'Comunicados',
  events: 'Eventos',
  messages: 'Messages',
  wallets: 'Carteiras',
  school_products: 'Cobranças',
  food_menus: 'Cardápios',
  albums: 'Mural de fotos',
  headquarter: 'Unidade',
  headquarters: 'Unidades',
  disciplines: 'Disciplinas',
  school_users: 'Equipe e Professores',
  student_profiles: 'Alunos',
  teacher: 'Professores',
  responsible_profiles: 'Responsáveis',
  survey: 'Enquete',
  surveys: 'Formulários (Enquetes e Autorizações)',
  activities: 'Atividades',
  groups: 'Grupos',
  documents: 'Documentos',
  daily_summaries: 'Diário',
};

export const TAB_NAMES = {
  features: 'Funcionalidades',
  organizations: 'Organização',
  financial: 'Financeiro',
};
