/* eslint-disable no-prototype-builtins */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  DefaultThemeProps,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';

import { OmniChannelState } from 'store/messages/omniChannel/types';
import OmniChannelActions from 'store/messages/omniChannel/actions';

import { RecipientSelectList } from 'components/RecipientSelectList';
import { TabItem } from 'components/RecipientSelectList/types';
import { ChatContext } from 'core/contexts/OmniChannel/Chat';
import { RecipientsTagButtonList } from 'components/RecipientsTagButtonList';

const RecipientsStep = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['messages', 'common']);
  const { colors } = useTheme() as DefaultThemeProps;

  const { setChatFormFilters } = OmniChannelActions;

  const {
    form: { channel, isLoading, filters },
  } = useSelector((state: OmniChannelState) => state.omniChannel.chat);

  const [inputValue, setInputValue] = useState(filters?.name || '');
  const [timeoutId, setTimeoutId] = useState(null);

  const { setCurrentStep, isInfoStepValid } = useContext(ChatContext);

  const channelKind = channel?.attributes.kind;

  const nextStep = useCallback(() => {
    setCurrentStep(2);
  }, [setCurrentStep]);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.chat.${key}`, params),
    [t]
  );

  const handleChangeFilters = useCallback(
    (value: string) => {
      dispatch(
        setChatFormFilters({
          ...filters,
          name: value,
        })
      );
    },
    [dispatch, filters, setChatFormFilters]
  );

  const handleChange = (value: string) => {
    setInputValue(value);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      handleChangeFilters(value);
    }, 500);

    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    if (!filters?.name) {
      setInputValue('');
    }
  }, [filters?.name]);

  const footer = (
    <Box
      py="sm"
      position="fixed"
      display="flex"
      alignItems="center"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      <Button
        testId="save-form"
        ml="auto"
        onClick={nextStep}
        disabled={!isInfoStepValid}
      >
        {t('common:button.next')}
      </Button>
    </Box>
  );

  const TABS: TabItem[] = [
    channelKind !== 'family' && {
      title: tBase('classroom_tab_name'),
      key: 'classroom',
    },
    channelKind !== 'family' && {
      title: tBase('student_tab_name'),
      key: 'student',
    },
    channelKind !== 'family' && {
      title: tBase('responsible_tab_name'),
      key: 'responsible',
    },
    (channelKind === 'family' || channelKind === 'private_and_family') && {
      title: tBase('family_tab_name'),
      key: 'family',
    },
  ].filter((tab): tab is TabItem => Boolean(tab));

  return (
    <Box mx="auto" pb="20vh">
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        position="relative"
        mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      >
        <Box maxWidth="607px" px="auto" mt="xl3">
          <Box display="flex" flexDirection="column" gap="xl">
            <Text
              variant="title-bold-20"
              color="neutral.black"
              fontWeight={700}
              mb={0}
            >
              {tBase('destinations_title')}
            </Text>
            <Text variant="body-regular-14" mb={0} lineHeight="lg">
              {tBase('destinations_description')}
            </Text>
            <TextField
              placeholder={tBase('destinations_search_placeholder')}
              icon="search"
              fullWidth
              value={inputValue}
              onChange={(e) => handleChange(e.target.value)}
            />
          </Box>

          <Box display="flex" flexDirection="column" mt="lg" gap="lg">
            <RecipientSelectList
              tabs={TABS}
              isLoading={isLoading}
              kind={channelKind}
            />
            <RecipientsTagButtonList />
          </Box>
        </Box>

        {footer}
      </Box>
    </Box>
  );
};

export default withRouter(RecipientsStep);
