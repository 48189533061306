import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Modal } from '@agendaedu/ae-web-components';

import FormContainer from 'components/Form/FormContainer';
import Loader from 'components/Loader';

import ModalFormByMethod from './form/index';

import { HandleModalByMethodProps } from './types';
import * as S from './styles';

const HandleModalByMethod = ({
  method = 'billet',
  showModal = false,
  toggleModal,
  isLoading = false,
  student,
  responsibles = [],
  onSubmit,
  checkoutError,
  link = null,
}: HandleModalByMethodProps) => {
  const { t } = useTranslation(['payments']);

  const toggle = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  return (
    <div data-testid="handle-modal-by-method">
      {showModal && (
        <Modal
          isOpen={showModal}
          title={
            <Text variant="title-bold-20" fontWeight={700} color="black" mb={0}>
              {t(`modals.generate.title`)}
            </Text>
          }
          onClose={toggle}
        >
          <S.ModalWrapper data-testid="modal-by-method">
            {isLoading && student ? (
              <S.LoaderWrapper>
                <Loader />
              </S.LoaderWrapper>
            ) : (
              <>
                {student !== undefined && student.attributes && (
                  <Text
                    variant="body-regular-16"
                    textAlign="left"
                    lineHeight="lg"
                    mt={16}
                    mb={0}
                  >
                    {t('modals.generate.subtitle', {
                      student: student.attributes.name,
                    })}
                    {t(`modals.generate.subtitle_${method}`)}:
                  </Text>
                )}

                {checkoutError && (
                  <Text
                    variant="body-bold-14"
                    color="context.alert.default"
                    textAlign="center"
                    mt={16}
                    mb={0}
                  >
                    {!!link &&
                    checkoutError.description.match(/cobrança.*atrasada/i) ? (
                      <>
                        {t(`modals.generate.error_description.part_1`)}
                        {t(`methods.${method}`)},
                        {t(`modals.generate.error_description.part_2`)}
                        <a href={link}>
                          {t(`modals.generate.error_description.part_3`)}
                        </a>
                      </>
                    ) : (
                      <>{checkoutError.description}</>
                    )}
                  </Text>
                )}

                <FormContainer
                  initialAttributes={{ responsible_profile_id: '', cpf: '' }}
                >
                  <ModalFormByMethod
                    method={method}
                    responsibles={responsibles}
                    toggleModal={toggle}
                    onSubmit={onSubmit}
                  />
                </FormContainer>
              </>
            )}
          </S.ModalWrapper>
        </Modal>
      )}
    </div>
  );
};

export default HandleModalByMethod;
